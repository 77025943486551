import React from 'react';
import { Timetable, Layout } from '../components/Components';

const Stundenplan = props => {
  return (
    <Layout>
      <h1 className="headline">Stundenplan</h1>
      <Timetable />
    </Layout>
  );
};

export default Stundenplan;
