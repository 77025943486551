import React, { useEffect, useState } from 'react';
import { LoginForm, Layout } from '../components/Components';
import { Redirect, Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { css } from 'emotion';

const Login = props => {
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [logoutReason, setLogoutReason] = useState();
  useEffect(() => {
    if (props.location.state) {
      setLogoutReason(props.location.state.logoutReason);
      window.history.pushState(null, '');
      if (props.location.state.logout) {
        removeCookie('token');
      }
    } else if (cookies.token) {
      setIsLoggedIn(true);
    }
    return () => {};
  }, [cookies.token, props.location.state, removeCookie]);

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }
  return (
    <Layout>
      <h1 className="headline">Anmelden</h1>
      {logoutReason && (
        <p
          className={css`
            background-color: #c43939;
            color: white;
            padding: 0.33rem;
            border-radius: 0.33rem;
          `}
        >
          {logoutReason}
        </p>
      )}
      <LoginForm action="/login"></LoginForm>
      <Link
        to="/forgot_password"
        className={css`
          margin-top: 2rem;
        `}
      >
        Passwort vergessen?
      </Link>
    </Layout>
  );
};

export default Login;
