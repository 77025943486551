import React, { useState, useEffect } from 'react';
import {
  ReloadButton,
  FontAwesomeButton,
  ReleaseAttendee,
  Confirm,
  Table,
  ButtonWrapper,
  LinkTd
} from './Components';
import fetchAPI from '../functions/fetchAPI';
import { css } from 'emotion';

const StagedAttendeesList = props => {
  const [stagedAttendees, setStagedAttendees] = useState([]);
  const [releaseHidden, setReleaseHidden] = useState(true);
  const [releaseStageID, setReleaseStageID] = useState(undefined);
  const [removeHidden, setRemoveHidden] = useState(true);
  const [removeStageID, setRemoveStageID] = useState(undefined);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    async function fetchStagedAttendees() {
      if (props.groupID) {
        await fetchAPI('post', {
          path: '/memberAPI/getstageattendeebygroup',
          body: {
            groupID: props.groupID
          },
          signal: signal
        })
          .then(res => res.json())
          .then(res => setStagedAttendees(res))
          .catch(err => {});
      } else {
        await fetchAPI('post', {
          path: '/memberAPI/getstageattendee',
          signal: signal
        })
          .then(res => res.json())
          .then(res => setStagedAttendees(res))
          .catch(err => {});
      }
    }
    fetchStagedAttendees();
    return () => {
      abortController.abort();
    };
  }, [props.refresh, props.groupID, reload]);

  function stagedAttendeeRows() {
    return stagedAttendees.map(attendee => {
      let {
        stageID,
        groupID,
        first_name,
        last_name,
        birthday,
        phone_number,
        isMember
      } = attendee;
      return (
        <tr
          key={stageID}
          className={css`
            background-color: ${groupID
              ? isMember
                ? 'lightgreen'
                : '#ff5959'
              : ''};
          `}
        >
          <td>{stageID}</td>
          {!props.groupID && groupID && (
            <LinkTd
              className={css`
                cursor: pointer;
                text-align: center;
              `}
              to={{
                pathname: '/groupinfo',
                state: { groupID: groupID }
              }}
            >
              {groupID}
            </LinkTd>
          )}
          <td>{first_name}</td>
          <td>{last_name}</td>
          <td>{birthday}</td>
          <td>{phone_number}</td>
          <td>
            <ButtonWrapper tdWrapper>
              {!props.groupID && groupID && isMember && (
                <FontAwesomeButton
                  colorPre="add"
                  icon="plus"
                  onClick={() => {
                    toggleRelease(stageID);
                  }}
                />
              )}
              {!props.groupID && groupID && !isMember && (
                <FontAwesomeButton
                  color="black"
                  icon="eye"
                  onClick={() => {
                    alert(
                      'Es wurde kein Vereinsmitglied mit dem gleichen Namen gefunden!'
                    );
                  }}
                />
              )}
              {props.groupID && (
                <FontAwesomeButton
                  color="black"
                  icon="trash-alt"
                  onClick={() => {
                    toggleRemove(stageID);
                  }}
                />
              )}
            </ButtonWrapper>
          </td>
        </tr>
      );
    });
  }

  async function removeStagedAttendee() {
    await fetchAPI('post', {
      path: '/memberAPI/deletestageattendee',
      body: {
        stageID: removeStageID,
        groupID: props.groupID
      }
    })
      .then(toggleRemove())
      .catch(err => {});
  }

  function toggleRemove(stageID) {
    setRemoveHidden(!removeHidden);
    if (removeStageID === undefined) {
      setRemoveStageID(stageID);
    } else {
      setRemoveStageID(undefined);
    }
  }

  function toggleRelease(stageID) {
    setReleaseHidden(!releaseHidden);
    if (releaseStageID === undefined) {
      setReleaseStageID(stageID);
    } else {
      setReleaseStageID(undefined);
    }
  }

  const salHeader = css`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    & h2,
    & h3 {
      text-align: center;
      margin-right: 0.5rem;
    }
  `;

  if (stagedAttendees.length > 0) {
    return (
      <div className="StagedAttendeesList">
        <div className={salHeader}>
          <h2 className="headline">unbestätigte Teilnehmer</h2>
          <ReloadButton
            onClick={() => {
              setReload(!reload);
            }}
          />
        </div>
        <Table
          tableHead={
            <tr>
              <th>ID</th>
              {!props.groupID && <th>Gruppe</th>}
              <th>Vorname</th>
              <th>Nachname</th>
              <th>Geburtstag</th>
              <th>Telefon</th>
              <th></th>
            </tr>
          }
          tableBody={stagedAttendeeRows()}
        />
        {!removeHidden && (
          <Confirm
            onClickBack={() => {
              toggleRemove();
            }}
            onClickConfirm={() => {
              removeStagedAttendee();
              props.onChange();
            }}
          >
            Wollen sie die Bestätigungsanfrage für den Teilnehmer mit der
            StageID: {removeStageID} zurückziehen und somit den Teilnehmer für{' '}
            <u>immer</u> entfernen?
          </Confirm>
        )}
        {!releaseHidden && (
          <ReleaseAttendee
            stageID={releaseStageID}
            onClickBack={() => {
              toggleRelease();
            }}
            onClickAdd={() => {
              toggleRelease();
              setTimeout(() => {
                setReload(!reload);
              }, 100);
            }}
          />
        )}
      </div>
    );
  } else {
    return (
      <div className="StagedAttendeesList">
        <div className={salHeader}>
          <h3 className="headline">keine unbestätigten Teilnehmer gefunden</h3>
          <ReloadButton
            onClick={() => {
              setReload(!reload);
            }}
          />
        </div>
      </div>
    );
  }
};

export default StagedAttendeesList;
