import React from 'react';
import { Link } from 'react-router-dom';

function LinkTd({ to, children, className, ...rest }) {
  return (
    <td
      className={className}
      onClick={e => {
        if (e.target.querySelector(':scope > a') !== null) {
          e.target.querySelector(':scope > a').click();
        }
      }}
    >
      <Link to={to} {...rest}>
        {children}
      </Link>
    </td>
  );
}

export default LinkTd;
