import mod from './mod';

export function addDays(date, days) {
  let result = new Date(date);
  return new Date(result.setDate(result.getDate() + days));
}

export function formatDay(day) {
  return new Date(day).toLocaleDateString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit'
  });
}

export function dateToDBString(date) {
  return date.toISOString().slice(0, 10);
}

export function getDayNumber(day) {
  switch (day) {
    case 'Sonntag':
      return 0;
    case 'Montag':
      return 1;
    case 'Dienstag':
      return 2;
    case 'Mittwoch':
      return 3;
    case 'Donnerstag':
      return 4;
    case 'Freitag':
      return 5;
    case 'Samstag':
      return 6;
    default:
      return -1;
  }
}

export function getNextDay(day) {
  if (day) {
    let weekday = getDayNumber(day);
    if (weekday === new Date().getDay()) {
      return new Date();
    } else {
      return addDays(new Date(), mod(weekday - new Date().getDay(), 7));
    }
  }
}
