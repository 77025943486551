import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { User } from '../functions/ReactContext';
import { css } from 'emotion';
import { useMedia } from '../functions/useMedia';
import { FontAwesomeButton, NavLinkList } from './Components';

const Navbar = props => {
  const mq = [600, 960, 1280, 1920].map(bp => `@media (max-width: ${bp}px)`);
  const user = useContext(User);
  const [showVertical, setShowVertical] = useState(false);

  const navbar = css`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    background-color: #ee3b3b;
    color: white;
    font-family: 'Rubik', sans-serif;
    padding: 1rem;
  `;
  return (
    <nav className={navbar}>
      <Link
        onClick={() => {
          setShowVertical(false);
        }}
        className={css`
          color: white;
          text-decoration: none;
          font-size: 1.25rem;
          outline: none;
          ${mq[1]} {
            width: 75%;
          }
          label {
            cursor: pointer;
          }
        `}
        to="/"
        tabIndex="-1"
      >
        <label htmlFor={props.id}>Lüner Sportverein Turnen von 1862 e.V.</label>
      </Link>
      {useMedia() >= 2 ? (
        <NavLinkList onClick={() => {}} />
      ) : user ? (
        <FontAwesomeButton
          size="2x"
          icon="bars"
          onClick={() => {
            setShowVertical(!showVertical);
          }}
        />
      ) : null}
      {showVertical && (
        <NavLinkList
          vertical
          onClick={() => {
            setShowVertical(false);
          }}
        />
      )}
    </nav>
  );
};

export default Navbar;
