import { __HOSTNAME__ } from '../host.config';

const fetchAPI = async (method, args) => {
  if (typeof args === 'object') {
    const path = args.path || '/';
    const body = args.body || {};
    const signal = args.signal || undefined;
    if (document.cookie) {
      body.token = document.cookie
        .match('(^|[^;]+)\\s*token\\s*=\\s*([^;]+)')
        .pop();
    }
    let res = await fetch(__HOSTNAME__ + path, {
      signal: signal,
      method: method,
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    await handleStatus(res);
    return new Promise(resolve => {
      resolve(res);
    });
  } else return new Promise();
};

export default fetchAPI;

async function handleStatus(res) {
  console.log(res);
  switch (res.status) {
    case 500:
      alert(
        '"Status 500: Internal Server Error"\nKontaktieren Sie bitte einen Administrator, wenn dieser Fehler unvorhersehbar geschehen ist.'
      );
      break;
    case 403:
      if (res.statusText !== 'Reset Token Failure') {
        alert(
          '"Status 403: Forbidden"\nSie haben keine Berechtigung um auf diese Seite zu gehen. Falls dies unerwartet ist, wenden Sie sich bitte an einen Administrator.'
        );
      }
      break;
    default:
      break;
  }
}
