import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { css } from 'emotion';

const FontAwesomeButton = ({
  color,
  colorPre,
  onClick,
  icon,
  className,
  ...props
}) => {
  const icon_list = [
    'user-plus',
    'plus',
    'user-edit',
    'ban',
    'trash-alt',
    'eye',
    'user-minus',
    'minus',
    'edit',
    'trash',
    'bars'
  ];

  function color_pre() {
    switch (colorPre) {
      case 'delete':
        return '#c43b3b';
      case 'add':
        return '#3ba93b';
      case 'edit':
        return '#ff9311';
      default:
        break;
    }
  }
  if (icon_list.includes(icon)) {
    return (
      <FontAwesomeIcon
        onClick={onClick}
        icon={icon}
        className={css`
          color: ${colorPre ? color_pre() : color};
          cursor: pointer;
          ${className}
        `}
        size={props.size}
        // {...props}
      />
    );
  }
  return null;
};

export default FontAwesomeButton;
