import { useState, useEffect } from 'react';

export function useMedia() {
  const [screenWidth, setScreenWidth] = useState(null);
  const checkScreenSize = () => {
    if (window.innerWidth < 600) {
      setScreenWidth(0);
    } else if (window.innerWidth < 960) {
      setScreenWidth(1);
    } else if (window.innerWidth < 1280) {
      setScreenWidth(2);
    } else if (window.innerWidth < 1920) {
      setScreenWidth(3);
    }
  };
  useEffect(() => {
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  return screenWidth;
}
