import React from 'react';
import { css } from 'emotion';

const Form = ({ children }) => {
  const form = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4rem;
  `;

  return <div className={form}>{children}</div>;
};

export default Form;
