import React from 'react';
import { css } from 'emotion';

const ButtonWrapper = ({ children, className, tdWrapper }) => {
  const mq = [600, 960, 1280, 1920].map(bp => `@media (max-width: ${bp}px)`);
  const buttonWrapper = css`
    display: flex;
    margin: 0.5rem auto;
    justify-content: space-evenly;
    ${mq[3]} {
      width: 17rem;
    }
    ${mq[2]} {
      width: 17rem;
    }
    ${mq[1]} {
      width: 15rem;
    }
    ${mq[0]} {
      width: 13rem;
    }
  `;
  return (
    <div
      className={
        buttonWrapper +
        ' ' +
        className +
        ' ' +
        (tdWrapper
          ? css`
              width: 3.5rem !important;
              margin-top: 0;
            `
          : '')
      }
    >
      {children}
    </div>
  );
};

export default ButtonWrapper;
