import React from 'react';
import { css } from 'emotion';
import mq from '../functions/mq';

const Table = ({ children, tableHead, tableBody, ...props }) => {
  return (
    <table
      {...props}
      className={css`
        border: 2px solid black;
        border-collapse: collapse;
        th {
          background-color: rgba(128, 128, 128, 0.295);
        }
        th,
        td {
          border: 1px solid black;
          padding: 0.5rem;
          text-align: left;
          vertical-align: middle;
          ${mq[0]} {
            padding: 0.4rem;
          }
        }
        a {
          cursor: pointer;
          color: black;
          text-decoration: none;
        }
      `}
    >
      <thead>{tableHead}</thead>
      <tbody>{tableBody}</tbody>
    </table>
  );
};

export default Table;
