import React, { useState, useEffect } from 'react';
import { ReleaseAttendeeCol, Button, ButtonWrapper, PopUp } from './Components';
import fetchAPI from '../functions/fetchAPI';
import { css } from 'emotion';

const ReleaseAttendee = props => {
  const [attendee, setAttendee] = useState(undefined);
  const [member, setMember] = useState(undefined);
  const [groupName, setGroupName] = useState(undefined);

  const releaseAttendee = css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 0.5fr 0.25fr 1fr 0.5fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    flex-wrap: wrap;
    width: 50vw;
    padding: 0 3vw 3vw;
    justify-content: space-evenly;
    & > h2,
    & > h3 {
      text-align: left;
      grid-area: 1 / 1 / 2 / 4;
    }
  `;

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    async function fetchAttendeeAndMember() {
      await fetchAPI('post', {
        path: '/memberAPI/stagedattendeeandmember',
        body: { stageID: props.stageID },
        signal: signal
      })
        .then(res => res.json())
        .then(res => {
          setAttendee(res.attendee);
          setMember(res.member);
          setGroupName(res.groupName);
        })
        .catch(err => {});
    }
    fetchAttendeeAndMember();
    return () => {
      abortController.abort();
    };
  }, [props.stageID]);

  async function addMemberToGroup() {
    if (
      window.confirm(
        `Wollen Sie den Teilnehmer ${attendee.first_name}, ${attendee.last_name} wirklich zu der Gruppe ${groupName} hinzufügen?`
      )
    ) {
      await fetchAPI('post', {
        path: '/memberAPI/addmembertogroup',
        body: {
          stageID: attendee.stageID,
          memberID: member.memberID,
          groupID: attendee.groupID
        }
      })
        .then(res => {
          if (res.status === 200) {
            alert('Teilnehmer erfolgreich hinzugefügt');
          }
        })
        .catch(err => {});
    }
  }
  return (
    <PopUp className={releaseAttendee}>
      <h1
        className={
          css`
            text-align: center;
            grid-area: 1 / 1 / 2 / 4 !important;
          ` + ' headline'
        }
      >
        Teilnehmer wirklich freigeben?
      </h1>
      <h2
        className={
          css`
            grid-area: 2 / 1 / 3 / 2 !important;
          ` + ' headline'
        }
      >
        Gruppe:
      </h2>
      {groupName && (
        <h3
          className={
            css`
              grid-area: 2 / 2 / 3 / 4 !important;
            ` + ' headline'
          }
        >
          {groupName}
        </h3>
      )}
      <div
        className={css`
          padding-top: 3.7rem;
          align-items: flex-end;
          padding-right: 1rem;
          display: flex;
          flex-direction: column;
        `}
      >
        <label>Vorname:</label>
        <label>Nachname:</label>
        <label>Geburstag:</label>
        <label>Geschlecht:</label>
        <label>Telefonnummer:</label>
      </div>
      <div className="attendee">
        {attendee && (
          <ReleaseAttendeeCol headline="Teilnehmer:" person={attendee} />
        )}
      </div>
      <div className="member">
        {member && <ReleaseAttendeeCol headline="Mitglied:" person={member} />}
      </div>
      <ButtonWrapper
        className={css`
          grid-area: 4 / 2 / 5 / 3;
          margin: auto;
        `}
      >
        <Button type="button" onClick={props.onClickBack}>
          zurück
        </Button>
        <Button
          type="button"
          onClick={() => {
            addMemberToGroup();
            props.onClickAdd();
          }}
        >
          Freigeben
        </Button>
      </ButtonWrapper>
    </PopUp>
  );
};

export default ReleaseAttendee;
