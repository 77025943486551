import React from 'react';
import { css } from 'emotion';

const Layout = ({ children, className }) => {
  const layout = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin: auto;
  `;

  return <div className={layout + ' ' + className}>{children}</div>;
};

export default Layout;
