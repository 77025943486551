import React from 'react';
import { GroupForm, Layout } from '../components/Components';

const AddGroup = props => {
  return (
    <Layout>
      <h1 className="headline">Gruppe hinzufügen</h1>
      <GroupForm method="post" />
    </Layout>
  );
};

export default AddGroup;
