import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { css } from 'emotion';

const Dropdown = ({ placeholder, title, list, onListItemClick, zIndex }) => {
  const dropdown = useRef(null);
  const mq = [600, 960, 1280, 1920].map(bp => `@media (max-width: ${bp}px)`);
  const [listOpen, setListOpen] = useState(false);
  const ddHeader = css`
    display: flex;
    background-color: white;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    cursor: pointer;
    justify-content: space-between;
    position: relative;
    z-index: ${zIndex || 2};
    width: calc(100% - 1.5rem);
    height: inherit;
    padding: 0 0.75rem 0.25rem;
  `;

  const ddList = css`
    position: absolute;
    top: 0.5rem;
    background-color: white;
    list-style-type: none;
    display: none;
    border: 1px solid #ced4da;
    border-top: none;
    border-radius: 0 0 0.5rem 0.5rem;
    padding: 0 0.75rem;
    margin: 0;
    max-height: 6rem;
    overflow: auto;
    z-index: ${zIndex - 1 || 1};
    width: calc(100% - 1.5rem);
    padding-top: ${dropdown.current
      ? dropdown.current.clientHeight + 'px'
      : ''};
  `;

  const label = css`
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    margin-bottom: 0;
    line-height: 1.5;
    color: gray;
    cursor: text;
    border: 1px solid transparent;
    border-radius: 0.25em;
    transition: all 0.1s ease-in-out;
    pointer-events: none;
    font-size: 0.75rem;
    padding: 0.25rem 0.75rem;
  `;

  const ddListItem = css`
    cursor: pointer;
    padding: 0.25rem;
    border-radius: 0.4rem;
    &:hover {
      background-color: lightgray;
    }
  `;

  const listShow = css`
    display: block !important;
  `;

  function toggleList() {
    listOpen ? setListOpen(false) : setListOpen(true);
  }

  const handleClick = e => {
    if (!dropdown.current.contains(e.target)) {
      setListOpen(false);
    }
    return;
  };

  useEffect(() => {
    document.addEventListener('mouseup', handleClick);
    return () => {
      document.removeEventListener('mouseup', handleClick);
    };
  });

  function listToLi(list) {
    if (Array.isArray(list)) {
      return list.map(e => {
        return (
          <li
            className={ddListItem}
            key={e}
            onMouseDown={async () => {
              onListItemClick(e);
            }}
          >
            {e}
          </li>
        );
      });
    }
  }

  return (
    <div
      ref={dropdown}
      className={css`
        position: relative;
        margin-bottom: 1rem;
        ${mq[3]} {
          width: 25rem;
        }
        ${mq[2]} {
          width: 25rem;
        }
        ${mq[1]} {
          width: 20rem;
        }
        ${mq[0]} {
          width: 15rem;
        }
      `}
    >
      <div className={ddHeader} onClick={toggleList}>
        <label className={label}>{placeholder}</label>
        <div
          className={css`
            padding: 1.25rem 0.75rem 0.25rem 0.25rem;
          `}
        >
          {title}
        </div>
        <FontAwesomeIcon
          className={css`
            margin-top: 1rem;
          `}
          icon={listOpen ? 'angle-up' : 'angle-down'}
        />
      </div>
      <ul
        className={ddList + (listOpen ? ' ' + listShow : '')}
        onClick={toggleList}
      >
        {listToLi(list)}
      </ul>
    </div>
  );
};

export default Dropdown;
