import React, { useState, useEffect } from 'react';
import { Dropdown, FontAwesomeButton } from './Components';
import { Link } from 'react-router-dom';
import fetchAPI from '../functions/fetchAPI';
import { css } from 'emotion';

const Timetable = props => {
  const weekdays = [
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
    'Sonntag'
  ];
  const times = ['15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00'];
  const [groups, setGroups] = useState(undefined);
  const [groupDivLoaded, setGroupDivLoaded] = useState(false);
  const [groupDiv, setGroupDiv] = useState([]);
  const [gymList, setGymList] = useState([]);
  const [selected, setSelected] = useState('');

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    async function fetchGroups() {
      if (selected === 'Alle') {
        setSelected('');
      }
      if (selected.length > 0) {
        await fetchAPI('post', {
          path: '/groupAPI/getgroupbygym',
          body: { gym: selected },
          signal: signal
        })
          .then(res => res.json())
          .then(res => setGroups(res))
          .catch(err => {});
      } else {
        await fetchAPI('post', {
          path: '/groupAPI/getgroup',
          signal: signal
        })
          .then(res => res.json())
          .then(res => setGroups(res))
          .catch(err => {});
      }
    }
    async function getGyms() {
      await fetchAPI('post', {
        path: '/groupAPI/getgym',
        signal: signal
      })
        .then(res => res.json())
        .then(res => {
          let arr = [];
          res.forEach(element => {
            arr.push(element.gym);
          });
          arr.unshift('Alle');
          setGymList(arr);
        })
        .catch(err => {});
    }

    fetchGroups();
    getGyms();
    return () => {
      abortController.abort();
    };
  }, [selected]);

  function timesSidebar() {
    return (
      <div className={sidebar}>
        <p>Zeit</p>
        <div className={sidebarWrapper}>
          {times.map(time => {
            return (
              <div
                key={time}
                name={'hour-' + time.slice(0, 2)}
                className={sidebarItem}
              >
                <p>{time}</p>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  function timetableColumns() {
    groupsToDiv();
    return weekdays.map(day => {
      return (
        <div key={day} name={day} className={column}>
          <p>{day}</p>
          <div className={groupWrapper}>
            {groupDiv.map(group => {
              if (group.weekday === day) {
                return group.div;
              }
              return null;
            })}
          </div>
        </div>
      );
    });
  }

  function timeDiffInQuarters(time1, time2, type) {
    time1 = time1.split(':');
    time2 = time2.split(':');
    time1[0] = parseInt(time1[0]);
    time1[1] = parseInt(time1[1]);
    time2[0] = parseInt(time2[0]);
    time2[1] = parseInt(time2[1]);
    let hourdiff = time2[0] - time1[0];
    let quarterdiff = time1[1] + time2[1];
    return (
      (hourdiff -
        (quarterdiff / 15 !== 0 && hourdiff > 1 && type === 'height' ? 1 : 0)) *
        4 +
      quarterdiff / 15
    );
  }

  function groupsToDiv() {
    if (groups && !groupDivLoaded) {
      setGroupDivLoaded(true);
      setGroupDiv(
        groups.map(group => {
          return {
            weekday: group.weekday,
            div: (
              <div
                key={group.groupID}
                className={
                  timetableItem +
                  ' ' +
                  css`
                    margin-top: ${timeDiffInQuarters(
                      '15:00',
                      group.start_time
                    )}rem;
                    height: ${timeDiffInQuarters(
                      group.start_time,
                      group.end_time,
                      'height'
                    )}rem;
                  `
                }
              >
                <Link
                  className={css`
                    text-decoration: none;
                    color: black;
                  `}
                  to={{
                    pathname: '/groupinfo',
                    state: { groupID: group.groupID }
                  }}
                >
                  <p>
                    {group.name}
                    <br />
                    {group.gymKRZ}
                    <br />
                    {group.start_time + '-' + group.end_time}
                  </p>
                </Link>
              </div>
            )
          };
        })
      );
    }
  }

  const timetable = css`
    max-width: 100vw;
    background-color: lightblue;
    display: flex;
  `;

  const column = css`
    min-width: 7rem;
    max-width: 7rem;
    text-align: center;
    background-image: linear-gradient(
      to right,
      black 0.0625rem,
      transparent 0.0625rem
    );
  `;

  const columnWrapper = css`
    overflow: auto;
    background-color: lightcoral;
    display: flex;
  `;

  const groupWrapper = css`
    height: 28rem;
    background-size: 7rem 4rem;
    background-image: linear-gradient(
      to bottom,
      black 0.0625rem,
      transparent 0.0625rem
    );
    position: relative;
  `;

  const timetableItem = css`
    position: absolute;
    left: 0.0625rem;
    min-width: 6.9375rem;
    max-width: 6.9375rem;
    border-top: inset 0.0625rem black;
    border-bottom: inset 0.0625rem black;
    background-color: lightgreen;
  `;

  const sidebar = css`
    width: 3rem;
    display: flex;
    flex-direction: column;
    text-align: center;
  `;

  const sidebarItem = css`
    p {
      height: 4rem;
      margin: 0;
    }
  `;

  const sidebarWrapper = css`
    background-size: 6rem 4rem;
    background-image: linear-gradient(
      to bottom,
      black 0.0625rem,
      transparent 0.0625rem
    );
  `;

  return (
    <div>
      <div
        className={css`
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <Dropdown
          placeholder="Turnhalle"
          title={selected.length <= 0 ? 'Alle' : selected}
          list={gymList}
          onListItemClick={setSelected}
        />
        <span
          className={css`
            border: 0.0625rem solid gray;
            border-radius: 0.5rem;
            cursor: pointer;
            display: flex;
            padding: 0.2rem;
            margin-left: 1rem;
            height: 1rem;
          `}
          onClick={() => {
            setSelected('');
          }}
        >
          <FontAwesomeButton colorPre="delete" icon="ban" /> Clear Filter
        </span>
      </div>
      <div className={timetable}>
        <div>{timesSidebar()}</div>
        <div className={columnWrapper}>{timetableColumns()}</div>
      </div>
    </div>
  );
};

export default Timetable;
