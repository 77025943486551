import React, { useCallback, useEffect, useState, useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import {
  AttendeesList,
  Button,
  AddAttendee,
  StagedAttendeesList,
  InactiveAttendeesList,
  StaffList,
  PresentCheck,
  ButtonWrapper
} from './Components';
import fetchAPI from '../functions/fetchAPI';
import { User } from '../functions/ReactContext';
import { getNextDay, dateToDBString } from '../functions/dateFunctions';
import { css } from 'emotion';
const GroupInfoTable = props => {
  const mq = [600, 960, 1280, 1920].map(bp => `@media (max-width: ${bp}px)`);
  const [presentCheckHidden, setPresentCheckHidden] = useState(false);
  const [group, setGroup] = useState(undefined);
  const [trainer, setTrainer] = useState(undefined);
  const [helper, setHelper] = useState(undefined);
  const [attendeeRefresh, setAttendeeRefresh] = useState(false);
  const history = useHistory();
  const user = useContext(User);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    async function fetchGroup() {
      if (props.groupID) {
        await fetchAPI('post', {
          path: '/groupAPI/getgroupinfo',
          body: { groupID: props.groupID },
          signal: signal
        })
          .then(res => res.json())
          .then(res => {
            setGroup(res.group);
            setTrainer(res.trainer);
            setHelper(res.helper);
          })
          .catch(err => {});
      }
    }
    fetchGroup();
    return () => {
      abortController.abort();
    };
  }, [props.groupID]);

  function toggleAttendeeAdd() {
    if (document.getElementById('add-attendee').classList.contains('hidden')) {
      document.getElementById('add-attendee').classList.remove('hidden');
    } else {
      document.getElementById('add-attendee').classList.add('hidden');
    }
  }

  function handleChange() {
    setTimeout(() => {
      setAttendeeRefresh(!attendeeRefresh);
    }, 100);
  }

  const handleAddAttendeeBtn = useCallback(async () => {
    const stageBody = {
      groupID: props.groupID,
      first_name: document.getElementById('aa-first_name').value,
      last_name: document.getElementById('aa-last_name').value,
      birthday: document.getElementById('aa-birthday').value,
      phone_number: document.getElementById('aa-phone_number').value
    };
    await fetchAPI('post', {
      path: '/memberAPI/addstageattendee',
      body: stageBody
    }).then(toggleAttendeeAdd());
  }, [props.groupID]);

  async function handlePresentClicked(present) {
    await fetchAPI('post', {
      path: '/userAPI/setuserpresentself',
      body: {
        date: dateToDBString(getNextDay(group.weekday)),
        userID: user.userID,
        groupID: group.props.groupID,
        present: present
      }
    })
      .then(res => res.json())
      .then(res => {
        if (res.status === 200) {
          setPresentCheckHidden(true);
        }
      });
  }

  const groupInfoTableWrapper = css`
    width: 80%;
    border: 1px solid gray;
    border-radius: 0.5rem;
    ${mq[2]} {
      width: 100%;
      border: none;
    }
  `;

  const gitHeader = css`
    display: flex;
    border-bottom: 1px solid gray;
    align-content: center;
    height: 3rem;
    justify-content: space-evenly;
    ${mq[2]} {
      flex-wrap: wrap;
      height: 6rem;
      border: 1px solid gray;
      border-radius: 0.5rem;
    }
  `;

  const gitHeaderItem = css`
    align-self: center;
    margin: 0;
    height: 3rem;
    border-left: 1px solid gray;
    line-height: 1.5rem;
    ${mq[2]} {
      border-left: none;
    }
  `;

  const gitHeaderItemContent = css`
    text-align: center;
    font-size: 0.85rem;
  `;

  const gitHeaderTitle = css`
    font-size: x-large;
    font-weight: bold;
    text-align: center;
    line-height: 3rem;
    flex: none !important;
    ${mq[2]} {
      flex: 1 1 100% !important;
      border-bottom: 1px solid gray;
    }
  `;

  const gitBody = css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `;

  const gitBodyItem = css``;

  if (group) {
    return (
      <div className={groupInfoTableWrapper}>
        <div className={gitHeader}>
          <div className={gitHeaderTitle + ' headline'}>{group.name}</div>
          <div className={gitHeaderItem}>
            Halle:
            <br />
            <span className={gitHeaderItemContent}>{group.gym}</span>
          </div>
          <div className={gitHeaderItem}>
            {group.weekday}
            <br />
            {group.start_time + '-' + group.end_time + ' Uhr'}
          </div>
        </div>
        <div className={gitBody}>
          {user.position === 2 && (
            <div name="git-body-presentCheck" className={gitBodyItem}>
              {helper && getNextDay(group.weekday) === new Date() && (
                <PresentCheck
                  hidden={presentCheckHidden}
                  onYes={() => {
                    handlePresentClicked(true);
                  }}
                  onNo={() => {
                    handlePresentClicked(false);
                  }}
                />
              )}
            </div>
          )}
          {(user.position === 0 || user.position === 1) && (
            <div name="git-body-staffList" className={gitBodyItem}>
              {group && trainer && helper && <StaffList group={group} />}
            </div>
          )}
          {(user.position === 0 || user.position === 1) && (
            <div name="git-body-add-form">
              <AddAttendee
                id="add-attendee"
                onClickBack={() => {
                  toggleAttendeeAdd();
                }}
                onClickAdd={() => {
                  handleAddAttendeeBtn();
                }}
                onChange={() => {
                  handleChange();
                }}
              />
            </div>
          )}
          {(user.position === 0 || user.position === 1) && (
            <div name="git-body-memberTable" className={gitBodyItem}>
              <AttendeesList
                refresh={attendeeRefresh}
                onClickAdd={() => {
                  toggleAttendeeAdd();
                }}
                onChange={() => {
                  handleChange();
                }}
                groupID={props.groupID}
                weekday={group.weekday}
              />
            </div>
          )}
          {(user.position === 0 || user.position === 1) && (
            <div name="git-body-stagedAttendee" className={gitBodyItem}>
              <StagedAttendeesList
                refresh={attendeeRefresh}
                onChange={() => {
                  handleChange();
                }}
                groupID={props.groupID}
              />
            </div>
          )}
          {(user.position === 0 || user.position === 1) && (
            <div name="git-body-inactive-attendees" className={gitBodyItem}>
              <InactiveAttendeesList
                refresh={attendeeRefresh}
                onChange={() => {
                  handleChange();
                }}
                groupID={props.groupID}
              />
            </div>
          )}
          <ButtonWrapper>
            <Button
              id="back"
              type="button"
              onClick={() => {
                history.goBack();
              }}
            >
              zurück
            </Button>
            {(user.position === 0 || user.position === 1) && (
              <Link
                to={{
                  pathname: '/editgroup',
                  state: { groupID: props.groupID }
                }}
              >
                <Button id="edit-group-btn" type="button">
                  Ändern
                </Button>
              </Link>
            )}
          </ButtonWrapper>
        </div>
      </div>
    );
  }
  return null;
};

export default GroupInfoTable;
