import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { css } from 'emotion';

const ReloadButton = props => {
  const [rotate, setRotate] = useState(false);

  const rotateCss = css`
    animation: linear rotate-keyframes 1s;
    transform-origin: center;
    @keyframes rotate-keyframes {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  `;

  const reloadButton = css`
    display: inline-block;
    color: #3ba93b;
    cursor: pointer;
  `;

  return (
    <span
      className={reloadButton + (rotate ? ' ' + rotateCss : ``)}
      onClick={() => {
        setRotate(true);
        props.onClick();
      }}
      onAnimationEnd={() => {
        setRotate(false);
      }}
    >
      <FontAwesomeIcon icon="sync-alt" />
    </span>
  );
};

export default ReloadButton;
