import React, { useState, useEffect } from 'react';
import { Button, ButtonWrapper, PopUp } from './Components';
import { css } from 'emotion';
import mq from '../functions/mq';

const Alert = props => {
  const [hidden, setHidden] = useState();

  useEffect(() => {
    setHidden(props.hidden);
  }, [props.hidden]);

  const alert = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    padding: 0 2.5% 2.5%;
    ${mq[0]} {
      width: 90%;
    }
  `;

  return (
    <PopUp className={alert + (hidden ? ' hidden' : '')}>
      <h1 className="headline">{props.title}</h1>
      <p>{props.children}</p>
      <ButtonWrapper>
        <Button
          type="button"
          onClick={() => {
            props.onClickBack();
          }}
        >
          Verstanden
        </Button>
      </ButtonWrapper>
    </PopUp>
  );
};

export default Alert;
