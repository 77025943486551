import React, { useContext } from 'react';
import { User } from '../functions/ReactContext';
import { Button, ButtonWrapper } from './Components';
import { css } from 'emotion';

function PresentCheck(props) {
  const user = useContext(User);

  const presentCheck = css`
    display: ${props.hidden ? 'none' : 'flex'};
    flex-direction: column;
    align-items: center;
  `;

  return (
    <div className={presentCheck + (props.hidden ? ' hidden' : '')}>
      <p
        className={
          'headline ' +
          css`
            font-size: 1.5rem;
            margin-bottom: 1rem;
          `
        }
      >
        Hallo {user.first_name} {user.last_name}!
      </p>
      <p className="headline">Bist du heute anwesend?</p>
      <ButtonWrapper>
        <Button
          className={css`
            height: 3rem !important;
            border-radius: 0.3rem !important;
            background-color: #3ba93b;
          `}
          type="button"
          onClick={() => {
            props.onYes();
          }}
        >
          Ja
        </Button>
        <Button
          className={css`
            height: 3rem !important;
            border-radius: 0.3rem !important;
          `}
          type="button"
          onClick={() => {
            props.onNo();
          }}
        >
          Nein
        </Button>
      </ButtonWrapper>
    </div>
  );
}

export default PresentCheck;
