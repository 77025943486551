import React from 'react';
import { GroupList, Layout } from '../components/Components';

const Gruppen = props => {
  return (
    <Layout>
      <h1 className="headline">GruppenListe</h1>
      <GroupList />
    </Layout>
  );
};

export default Gruppen;
