import React, { useState, useRef } from 'react';
import { css } from 'emotion';

const DropdownWithInput = ({ zIndex, ...props }) => {
  const dropdown = useRef(null);
  const [listOpen, setListOpen] = useState(false);
  const mq = [600, 960, 1280, 1920].map(bp => `@media (max-width: ${bp}px)`);

  const ddHeader = css`
    display: flex;
    background-color: white;
    border-radius: 0.25rem;
    cursor: pointer;
    justify-content: space-between;
    position: relative;
    z-index: ${zIndex || 2};
    height: inherit;
  `;

  const input = css`
    font-size: 1rem;
    line-height: 1.5;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    background-clip: padding-box;
    outline: none;
    width: calc(100% - 1.5rem);
    padding: 0.75rem;
    padding-top: 1.25rem;
    padding-bottom: 0.25rem;
    &::placeholder {
      opacity: 0;
    }
  `;
  const ddList = css`
    position: absolute;
    top: 0.5rem;
    background-color: white;
    list-style-type: none;
    display: none;
    border: 1px solid #ced4da;
    border-top: none;
    border-radius: 0 0 0.5rem 0.5rem;
    padding: 0 0.7rem;
    margin: 0;
    max-height: 6rem;
    z-index: ${zIndex - 1 || 1};
    overflow: auto;
    width: calc(100% - 1.5rem);
    padding-top: ${dropdown.current
      ? dropdown.current.clientHeight + 'px'
      : ''};
    & li {
      cursor: pointer;
      padding: 0.25rem;
      border-radius: 0.4rem;
      &:hover {
        background-color: lightgray;
      }
    }
  `;

  const label = css`
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    margin-bottom: 0;
    line-height: 1.5;
    color: gray;
    cursor: text;
    border: 1px solid transparent;
    border-radius: 0.25em;
    transition: all 0.1s ease-in-out;
    pointer-events: none;
    font-size: 0.75rem;
    padding: 0.25rem 0.75rem;
  `;

  const listShow = css`
    display: block !important;
  `;
  return (
    <div
      ref={dropdown}
      className={css`
        font-size: 1rem;
        position: relative;
        margin-bottom: 1rem;
        ${mq[3]} {
          width: 25rem;
        }
        ${mq[2]} {
          width: 25rem;
        }
        ${mq[1]} {
          width: 20rem;
        }
        ${mq[0]} {
          width: 15rem;
        }
      `}
    >
      <div className={ddHeader}>
        <input
          type={props.type}
          onKeyDown={props.onKeyDown}
          disabled={props.disabled}
          className={input}
          id={props.id}
          name={props.id}
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
          onFocus={() => {
            setListOpen(true);
          }}
          onBlur={() => {
            setListOpen(false);
          }}
        />
        <label className={label}> {props.placeholder}: </label>
      </div>
      <ul className={ddList + (listOpen ? ' ' + listShow : '')}>
        {props.list}
      </ul>
    </div>
  );
};

export default DropdownWithInput;
