import React from 'react';
import { GroupForm, Layout } from '../components/Components';

const EditGroup = props => {
  return (
    <Layout>
      <h1 className="headline">Gruppe ändern</h1>
      <GroupForm method="put" groupID={props.location.state.groupID} />
    </Layout>
  );
};

export default EditGroup;
