import React from 'react';
import { GroupInfoTable, Layout } from '../components/Components';

const GroupInfo = props => {
  return (
    <Layout>
      <h1 className="headline">GruppenInfo</h1>
      <GroupInfoTable groupID={props.location.state.groupID} />
    </Layout>
  );
};

export default GroupInfo;
