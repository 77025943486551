import React from 'react';
import { InputField, Button, PopUp, ButtonWrapper } from './Components';
import { css } from 'emotion';
import mq from '../functions/mq';

const AddAttendee = props => {
  const addattendee = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    padding: 0 2.5% 2.5%;
    ${mq[2]} {
      width: 90%;
    }
  `;
  return (
    <div className={'hidden'} id={props.id}>
      <PopUp className={addattendee}>
        <h2 className="headline">Teilnehmer hinzufügen</h2>
        <InputField
          id="aa-first_name"
          type="text"
          value={''}
          placeholder="Vorname"
        />
        <InputField
          id="aa-last_name"
          type="text"
          value={''}
          placeholder="Nachname"
        />
        <InputField
          id="aa-birthday"
          type="text"
          value=""
          placeholder="Geburtstag (JJJJ-MM-TT)"
        />
        <InputField
          id="aa-phone_number"
          type="text"
          value=""
          placeholder="Telefonnummer"
        />
        <ButtonWrapper>
          <Button type="button" onClick={props.onClickBack}>
            zurück
          </Button>
          <Button
            type="button"
            onClick={() => {
              props.onClickAdd();
              props.onChange();
            }}
          >
            Hinzufügen
          </Button>
        </ButtonWrapper>
      </PopUp>
    </div>
  );
};

export default AddAttendee;
