import React from 'react';
import { css } from 'emotion';

const ReleaseAttendeeCol = props => {
  function phoneHelp() {
    if (Array.isArray(props.person.phone_number)) {
      return (
        <div
          className={css`
            list-style-type: none;
            padding: 0;
          `}
        >
          {props.person.phone_number.map(number => {
            return <li key={number}>{number}</li>;
          })}
        </div>
      );
    } else {
      return props.person.phone_number;
    }
  }

  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
      `}
    >
      <h3 className="headline">{props.headline}</h3>
      <div
        className={css`
          display: flex;
          flex-direction: column;
        `}
      >
        {props.person && (
          <label>
            {props.person.first_name ? props.person.first_name : '---'}
          </label>
        )}
        {props.person && (
          <label>
            {props.person.last_name ? props.person.last_name : '---'}
          </label>
        )}
        {props.person && (
          <label>{props.person.birthday ? props.person.birthday : '---'}</label>
        )}
        {props.person && (
          <label>{props.person.gender ? props.person.gender : '---'}</label>
        )}
        {props.person && (
          <label>{props.person.phone_number ? phoneHelp() : '---'}</label>
        )}
      </div>
    </div>
  );
};

export default ReleaseAttendeeCol;
