import React, { useState } from 'react';
import { InputField, Button, ButtonWrapper, Layout } from './Components';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import fetchAPI from '../functions/fetchAPI';
import { css } from 'emotion';

const LoginForm = props => {
  const history = useHistory();
  const [shown, setShown] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies();
  async function handleSubmit() {
    const reqBody = {
      email: document.getElementById('email').value,
      password: document.getElementById('password').value
    };
    await fetchAPI('post', {
      path: '/authAPI/authenticate',
      body: reqBody
    }).then(res => {
      if (res.status === 200) {
        setCookie('reload');
        removeCookie('reload');
        history.push('/');
      }
      if (res.status === 422) {
        setShown(true);
      }
    });
  }

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  }

  return (
    <Layout>
      {shown && (
        <p
          className={css`
            background-color: #c43939;
            color: white;
            padding: 0.33rem;
            border-radius: 0.33rem;
          `}
        >
          {'E-Mail oder Passwort ist falsch.'}
          <br />
          {'Überprüfen Sie Ihre Eingabe und versuchen Sie es erneut.'}
        </p>
      )}
      <InputField
        id="email"
        onKeyDown={e => {
          handleKeyDown(e);
        }}
        type="text"
        value=""
        placeholder="E-Mail"
        onChange={() => {
          if (shown) {
            setShown(false);
          }
        }}
      />
      <InputField
        id="password"
        onKeyDown={e => {
          handleKeyDown(e);
        }}
        type="password"
        value=""
        placeholder="Passwort"
        onChange={() => {
          if (shown) {
            setShown(false);
          }
        }}
      />
      <ButtonWrapper>
        <Button
          id="loginButton"
          type="button"
          onClick={() => {
            handleSubmit();
          }}
        >
          Anmelden
        </Button>
      </ButtonWrapper>
    </Layout>
  );
};

export default LoginForm;
