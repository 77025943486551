import React from 'react';
import { MemberForm, Layout } from '../components/Components';

const EditMember = props => {
  return (
    <Layout>
      <h1 className="headline">Mitglied ändern</h1>
      <MemberForm method="put" memberID={props.location.state.memberID} />
    </Layout>
  );
};

export default EditMember;
