import React, { useState } from 'react';
import { InputField, ButtonWrapper, Button, Form } from './Components';
import fetchAPI from '../functions/fetchAPI';
import { css } from 'emotion';
import { Link } from 'react-router-dom';

const ForgotPasswordForm = () => {
  const [submitted, setSubmitted] = useState(false);
  async function handleSubmit() {
    fetchAPI('post', {
      path: '/authAPI/forgot_password',
      body: { email: document.getElementById('email').value }
    }).then(res => {
      if (res.status === 200) {
        setSubmitted(true);
      } else {
        alert(
          'Irgendwas ist schiefgelaufen bei deiner anfrage, versuche es erneut!'
        );
      }
    });
  }

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  }

  if (submitted) {
    return (
      <div>
        <p
          className={css`
            color: #3ba93b;
          `}
        >
          Weitere Anweisungen, um dein Passwort zurückzusetzen wurden dir an die
          angegebene Email gesendet, wenn diese Registriert ist.
        </p>
        <Link to="/login">zum Login</Link>
      </div>
    );
  } else {
    return (
      <Form>
        <InputField
          id="email"
          onKeyDown={e => {
            handleKeyDown(e);
          }}
          type="text"
          value=""
          placeholder="E-Mail"
        />
        <ButtonWrapper>
          <Button
            type="button"
            onClick={() => {
              handleSubmit();
            }}
          >
            Abschicken
          </Button>
        </ButtonWrapper>
      </Form>
    );
  }
};

export default ForgotPasswordForm;
