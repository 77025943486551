import React, {
  useReducer,
  useCallback,
  useState,
  useEffect,
  useContext
} from 'react';
import {
  formatDay,
  getNextDay,
  dateToDBString
} from '../functions/dateFunctions';
import { Button, Table, ButtonWrapper } from './Components';
import { User } from '../functions/ReactContext';
import fetchAPI from '../functions/fetchAPI';
import { css } from 'emotion';

function StaffList(props) {
  const user = useContext(User);
  const [showSave, setShowSave] = useState(false);
  const [presentLoaded, setPresentLoaded] = useState(false);
  const [staffLoaded, setStaffLoaded] = useState(false);
  const [staff, setStaff] = useState([]);
  const [staffPresentState, staffPresentDispatch] = useReducer(
    presentUserReducer,
    []
  );

  const staffPresentInit = useCallback(
    users => {
      if (!presentLoaded) {
        staffPresentDispatch({ type: 'ADD_USERS', users: users });
      }
    },
    [presentLoaded]
  );
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    async function fetchStaff() {
      await fetchAPI('post', {
        path: '/groupAPI/getstaff',
        body: { groupID: props.group.groupID },
        signal: signal
      })
        .then(res => res.json())
        .then(res => {
          setStaffLoaded(true);
          let resStaff = [...res.trainer, ...res.helper];
          setStaff(resStaff);
          staffPresentInit(resStaff);
        })
        .catch(err => {});
    }
    if (!staffLoaded) {
      fetchStaff();
    }
    async function fetchPresentStaff() {
      await fetchAPI('post', {
        path: '/userAPI/getgrouppresentuser',
        body: {
          date: dateToDBString(getNextDay(props.group.weekday)),
          userID: user.userID,
          groupID: props.group.groupID
        },
        signal: signal
      })
        .then(res => res.json())
        .then(res => {
          setPresentLoaded(true);
          res.forEach(e => {
            staffPresentDispatch({
              type: 'ADD_PRESENT',
              userID: e.attendee_userID,
              save: false
            });
          });
        })
        .catch(() => {});
    }
    if (!presentLoaded && staffLoaded) {
      fetchPresentStaff();
    }

    return () => {
      abortController.abort();
    };
  }, [
    staffPresentInit,
    staff,
    staffPresentState,
    props.group.weekday,
    props.group.groupID,
    user.userID,
    presentLoaded,
    staffLoaded
  ]);

  function presentUserReducer(state, action) {
    switch (action.type) {
      case 'ADD_USERS':
        return [
          ...action.users.map(user => {
            return {
              userID: user.userID,
              present: false
            };
          })
        ];
      case 'ADD_PRESENT':
        if (action.save) {
          setShowSave(true);
        }
        staff.forEach(e => {
          if (e.userID === action.userID) {
            e.present = true;
          }
        });
        return [
          ...state.map(user => {
            if (user.userID === action.userID) {
              return { ...user, present: true };
            }
            return user;
          })
        ];
      case 'REMOVE_PRESENT':
        if (action.save) {
          setShowSave(true);
        }
        staff.forEach(e => {
          if (e.userID === action.userID) {
            e.present = false;
          }
        });
        return [
          ...state.map(user => {
            if (user.userID === action.userID) {
              return { ...user, present: false };
            }
            return user;
          })
        ];
      default:
        return state;
    }
  }

  function staffRows() {
    if (staffLoaded && presentLoaded) {
      return staff.map(user => {
        let { userID, first_name, last_name, position, present } = user;
        return (
          <tr key={userID}>
            <td>{userID}</td>
            <td>{first_name}</td>
            <td>{last_name}</td>
            <td>{position}</td>
            <td
              className={css`
                background-color: ${present ? 'lightgreen' : 'lightcoral'};
              `}
              onMouseDown={e => {
                staffPresentDispatch({
                  userID: userID,
                  type: present ? 'REMOVE_PRESENT' : 'ADD_PRESENT',
                  save: true
                });
              }}
            ></td>
          </tr>
        );
      });
    }
  }

  async function setGroupPresentUser() {
    await fetchAPI('post', {
      path: '/userAPI/setgrouppresentuser',
      body: {
        date: dateToDBString(getNextDay(props.group.weekday)),
        userID: user.userID,
        groupID: props.group.groupID,
        users: staffPresentState
      }
    }).then(res => {
      if (res.status === 200) {
        alert('Erfolgreich gespeichert.');
      } else if (res.status === 422) {
        alert(
          'Das Eintragen der Anwesenheit ist nur am gleichen Tag möglich.\n' +
            'Falls der Tag richtig ist, prüfe deine anderen Eingaben und wende dich ansonsten an einen Administrator.'
        );
        setPresentLoaded(false);
      } else {
        setPresentLoaded(false);
      }
      setShowSave(false);
    });
  }

  return (
    <div className="StaffList">
      <h2 className="headline">Übungsleiter/Helfer</h2>
      <Table
        tableHead={
          <tr>
            <th>ID</th>
            <th>Vorname</th>
            <th>Nachname</th>
            <th>Position</th>
            <th>{formatDay(getNextDay(props.group.weekday))}</th>
          </tr>
        }
        tableBody={staffRows()}
      />
      {showSave && (
        <ButtonWrapper>
          <Button
            type="button"
            onClick={() => {
              setGroupPresentUser();
            }}
          >
            Speichern
          </Button>
        </ButtonWrapper>
      )}
    </div>
  );
}

export default StaffList;
