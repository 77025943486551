import React, { useContext } from 'react';
import { User } from '../functions/ReactContext';
import { css } from 'emotion';
import { FontAwesomeButton } from './Components';
import mq from '../functions/mq';

const PersonalInfo = () => {
  const user = useContext(User);

  const personalInfo = css`
    width: 100%;
    display: flex;
    flex-direction: column;
  `;

  const section = css`
    margin: auto;
    width: 50%;
    ${mq[2]} {
      width: 100%;
    }
  `;

  const section_headline = css`
    text-align: left !important;
  `;

  const section_content = css`
    position: relative;
    display: flex;
    padding-left: 7rem;
    height: 4rem;
    ${mq[2]} {
      padding-left: 2.5rem;
    }
    label {
      width: 4rem;
    }
    p {
      text-align: left !important;
      padding-left: 2rem;
    }
  `;

  const editButton = css`
    position: absolute;
    right: 0;
  `;

  return (
    <div className={personalInfo}>
      <section name="Persönliches" className={section}>
        <div name="section-head">
          <h2 className={'headline ' + section_headline}>Persönliches</h2>
        </div>
        <div name="section-body">
          <div className={section_content}>
            <label htmlFor="full_name">Name:</label>
            <p id="full_name">{user.full_name}</p>
            <FontAwesomeButton
              className={editButton}
              colorPre="edit"
              icon="edit"
            />
          </div>
          <div className={section_content}>
            <label htmlFor="position">Position:</label>
            <p id="position">{user.position_full}</p>
          </div>
          <div className={section_content}>
            <label htmlFor="email">Email:</label>
            <p id="email">{user.email}</p>
            <FontAwesomeButton
              className={editButton}
              colorPre="edit"
              icon="edit"
            />
          </div>
          <div className={section_content}>
            <label htmlFor="password">Passwort:</label>
            <p>*********</p>
            <FontAwesomeButton
              className={editButton}
              colorPre="edit"
              icon="edit"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default PersonalInfo;
