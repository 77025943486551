import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  FontAwesomeButton,
  LinkTd,
  ButtonWrapper,
  Table,
  Button
} from './Components';
import fetchAPI from '../functions/fetchAPI';
import { User } from '../functions/ReactContext';
import { useMedia } from '../functions/useMedia';
import { css } from 'emotion';
import mq from '../functions/mq';
const GroupList = props => {
  const user = useContext(User);
  const [groups, setGroups] = useState([]);
  const [reload, setReload] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const mediaHide = css`
    ${mq[0]} {
      display: none;
    }
  `;
  const grouplist = css`
    max-width: 100vw;
    ${mq[0]} {
      font-size: 0.9rem;
    }
  `;

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    async function callGroupAPI() {
      await fetchAPI('post', {
        path: '/groupAPI/getgroup',
        signal: signal
      })
        .then(res => res.json())
        .then(res => setGroups(res))
        .catch(err => {});
    }

    callGroupAPI();
    return () => {
      abortController.abort();
    };
  }, [reload]);

  function tableRows() {
    if (groups.length > 0) {
      return groups.map(group => {
        group.time = group.start_time + '- ' + group.end_time;
        group = {
          groupID: group.groupID,
          name: group.name,
          weekday: group.weekday,
          time: group.time,
          gym: group.gym,
          gymKRZ: group.gymKRZ
        };
        return (
          <tr key={group.groupID}>
            {Object.entries(group).map(([key, value]) => {
              if (key === 'groupID' && editMode) {
                return (
                  <td key={key}>
                    <ButtonWrapper tdWrapper>
                      <Link
                        to={{
                          pathname: '/editgroup',
                          state: { groupID: group.groupID }
                        }}
                      >
                        <FontAwesomeButton colorPre="edit" icon="edit" />
                      </Link>
                      <FontAwesomeButton
                        colorPre="delete"
                        icon="trash"
                        onClick={() => deleteGroup(group.groupID)}
                      />
                    </ButtonWrapper>
                  </td>
                );
              }
              return (
                <LinkTd
                  key={key}
                  className={
                    key === 'gym' || key === 'start_time' || key === 'end_time'
                      ? mediaHide
                      : null
                  }
                  to={{
                    pathname: '/groupinfo',
                    state: { groupID: group.groupID }
                  }}
                >
                  {value}
                </LinkTd>
              );
            })}
          </tr>
        );
      });
    }
  }

  async function deleteGroup(groupID) {
    if (
      window.confirm(
        `Wollen Sie die Gruppe mit der ID: ${groupID} wirklich löschen?`
      )
    ) {
      await fetchAPI('delete', {
        path: '/groupAPI/deletegroup',
        body: { groupID: groupID }
      }).then(res => {
        if (res.status === 200) {
          alert('Die Gruppe wurde erfolgreich gelöscht.');
        }
      });

      setReload(!reload);
    }
  }

  return (
    <div name="groupList" className={grouplist}>
      {user.position === 0 && (
        <ButtonWrapper>
          <Button
            type="button"
            onClick={() => {
              setEditMode(!editMode);
            }}
          >
            Bearbeiten
          </Button>
          <Link to="/addgroup">
            <Button type="button">Hinzufügen</Button>
          </Link>
        </ButtonWrapper>
      )}
      <Table
        tableHead={
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Wochentag</th>
            <th>Zeit</th>
            {useMedia() > 0 && <th>Halle</th>}
            <th>{useMedia() < 1 ? 'Halle' : 'Kzf.'}</th>
          </tr>
        }
        tableBody={tableRows()}
      ></Table>
    </div>
  );
};

export default GroupList;
