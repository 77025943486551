import React, { useEffect, useState } from 'react';
import { FontAwesomeButton, Table, ButtonWrapper, Button } from './Components';
import { Link } from 'react-router-dom';
import fetchAPI from '../functions/fetchAPI';
import { css } from 'emotion';
import mq from '../functions/mq';

const MemberList = props => {
  const [members, setMembers] = useState([]);
  const [reload, setReload] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const memberlist = css`
    ${mq[1]} {
      font-size: 0.8rem;
    }
  `;

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    async function callMemberAPI() {
      await fetchAPI('post', { path: '/memberAPI/getmember', signal: signal })
        .then(res => res.json())
        .then(res => setMembers(res))
        .catch(err => {});
    }
    callMemberAPI();
    return () => {
      abortController.abort();
    };
  }, [reload]);

  function memberRows() {
    return members.map(member => {
      let { memberID, first_name, last_name, birthday, phone_number } = member;
      if (Array.isArray(phone_number)) {
        phone_number = phoneHelp(phone_number);
      }
      return (
        <tr key={memberID}>
          <td>
            {editMode ? (
              <ButtonWrapper tdWrapper>
                <Link
                  to={{
                    pathname: '/editmember',
                    state: { memberID: memberID }
                  }}
                >
                  <FontAwesomeButton colorPre="edit" icon="user-edit" />
                </Link>
                <FontAwesomeButton
                  colorPre="delete"
                  icon="user-minus"
                  onClick={() => {
                    deleteMember(memberID);
                  }}
                />
              </ButtonWrapper>
            ) : (
              memberID
            )}
          </td>
          <td>{first_name}</td>
          <td>{last_name}</td>
          <td>{birthday}</td>
          <td>{phone_number}</td>
        </tr>
      );
    });
  }

  async function deleteMember(memberID) {
    if (
      window.confirm(
        `Wollen Sie die das Mitglied mit der ID: ${memberID} wirklich löschen?`
      )
    ) {
      await fetchAPI('delete', {
        path: '/memberAPI/deletemember',
        body: { memberID: memberID }
      })
        .then(res => res.json())
        .then(res => {
          if (res.status === 200) {
            alert('Mitglied erfolgreich gelöscht.');
          }
        })
        .catch(err => {});
      setReload(!reload);
    }
  }

  function phoneHelp(phoneArray) {
    return (
      <div
        className={css`
          list-style-type: none;
          padding: 0;
        `}
      >
        {phoneArray.map(number => {
          return <li key={number}>{number}</li>;
        })}{' '}
      </div>
    );
  }

  return (
    <div name="MemberList" className={memberlist}>
      <ButtonWrapper>
        <Button
          type="button"
          onClick={() => {
            setEditMode(!editMode);
          }}
        >
          Bearbeiten
        </Button>
        <Link to="/addmember">
          <Button type="button">Hinzufügen</Button>
        </Link>
      </ButtonWrapper>
      <Table
        id="members"
        tableHead={
          <tr>
            <th>ID</th>
            <th>Vorname</th>
            <th>Nachname</th>
            <th>Geburtstag</th>
            <th>Telefon</th>
          </tr>
        }
        tableBody={memberRows()}
      />
    </div>
  );
};

export default MemberList;
