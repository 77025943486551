import React, { useEffect, useState, useContext } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { User } from '../functions/ReactContext';
import Alert from '../components/Alert';
import { useCookies } from 'react-cookie';

const WithAuth = (ComponentToProtect, props, restrict) => {
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [cookies] = useCookies();
  const user = useContext(User);
  const history = useHistory();
  useEffect(() => {
    if (cookies.token) {
      setLoading(false);
    } else {
      setLoading(false);
      setRedirect(true);
    }
  }, [cookies.token]);

  if (loading) {
    return null;
  }
  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: {
            logoutReason:
              'Es scheint so als wäre dein Login abgelaufen. Melde dich bitte erneut an.'
          }
        }}
      />
    );
  }
  if (restrict === 'adminOnly' && user.position !== 0) {
    return (
      <Alert
        title="Fehler: Zutritt verweigert!"
        onClickBack={() => {
          history.goBack();
        }}
      >
        Diese Fehlermeldung erscheint, weil Sie keine Berechtigung haben auf
        diese Seite zu zugreifen. Falls Sie der Meinung sind es handelt sich
        dabei um einen Fehler, kontaktieren Sie bitte einen Administrator.
      </Alert>
    );
  }
  return <ComponentToProtect {...props} />;
};

export default WithAuth;
