import React from 'react';
import { Navbar } from './components/Components';
import {
  Login,
  Home,
  AddMember,
  EditMember,
  EditGroup,
  AddGroup,
  Gruppen,
  Stundenplan,
  GroupInfo,
  Mitglieder,
  WithAuth,
  Profil,
  ForgotPassword,
  ResetPassword
} from './pages/Pages';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { withCookies, useCookies } from 'react-cookie';
import { User } from './functions/ReactContext';
import jwt_decode from 'jwt-decode';
import { Global, css } from '@emotion/core';
import 'typeface-rubik';
function App() {
  const [cookies] = useCookies();
  return (
    <div className="App">
      <Global
        styles={css`
          body {
            margin: 0;
          }
          .headline {
            text-align: center;
            font-weight: 500;
            font-family: Rubik;
            color: #c43939;
            flex: 1 100%;
          }
          .hidden {
            display: none !important;
          }
        `}
      />
      <User.Provider
        value={cookies.token ? jwt_decode(cookies.token).reqUser : undefined}
      >
        <Router>
          <Navbar id="navbar"></Navbar>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/forgot_password" component={ForgotPassword} />
            <Route path="/reset_password" component={ResetPassword} />
            <Route
              path="/mitglieder"
              component={props => {
                return WithAuth(Mitglieder, props);
              }}
            />
            <Route
              path="/gruppen"
              component={props => {
                return WithAuth(Gruppen, props);
              }}
            />
            <Route
              path="/stundenplan"
              component={props => {
                return WithAuth(Stundenplan, props);
              }}
            />
            <Route
              path="/profil"
              component={props => {
                return WithAuth(Profil, props);
              }}
            />
            <Route
              path="/addmember"
              component={props => {
                return WithAuth(AddMember, props, 'adminOnly');
              }}
            />
            <Route
              path="/editmember"
              component={props => {
                return WithAuth(EditMember, props, 'adminOnly');
              }}
            />
            <Route
              path="/addgroup"
              component={props => {
                return WithAuth(AddGroup, props, 'adminOnly');
              }}
            />
            <Route
              path="/editgroup"
              component={props => {
                return WithAuth(EditGroup, props, 'adminOnly');
              }}
            />
            <Route
              path="/groupinfo"
              component={props => {
                return WithAuth(GroupInfo, props);
              }}
            />
            <Route
              path="/"
              component={props => {
                return WithAuth(Home, props);
              }}
            />
          </Switch>
        </Router>
      </User.Provider>
    </div>
  );
}

export default withCookies(App);
