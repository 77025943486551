import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUserPlus,
  faUserEdit,
  faPlus,
  faMinus,
  faTrashAlt,
  faAngleUp,
  faAngleDown,
  faBan,
  faSyncAlt,
  faEye,
  faUserMinus,
  faEdit,
  faTrash,
  faBars
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faUserPlus,
  faUserEdit,
  faPlus,
  faMinus,
  faTrashAlt,
  faAngleUp,
  faAngleDown,
  faBan,
  faSyncAlt,
  faEye,
  faUserMinus,
  faEdit,
  faTrash,
  faBars
);
