import React from 'react';
import { css } from 'emotion';
import mq from '../functions/mq';

const Button = ({ id, type, disabled, onClick, children, className }) => {
  const button = css`
    border: 0.0625rem grey solid;
    font-size: 0.75rem;
    line-height: 1.5rem;
    cursor: pointer;
    border-radius: 0.5rem;
    text-align: center;
    outline: none;
    color: white;
    background-color: #c43b3b;
    ${mq[3]} {
      width: 8rem;
    }
    ${mq[2]} {
      width: 8rem;
    }
    ${mq[1]} {
      width: 7rem;
    }
    ${mq[0]} {
      width: 6rem;
    }
    ${className}
  `;

  const typeArray = ['submit', 'reset', 'button'];
  if (typeArray.includes(type)) {
    return (
      <button
        className={button}
        id={id}
        type={type}
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </button>
    );
  }
  return null;
};

export default Button;
