import React from 'react';
import { css } from 'emotion';

const PopUp = ({ children, className }) => {
  const popup = css`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(216, 216, 216);
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.75);
    border: 0.2rem solid rgb(216, 216, 216);
    border-radius: 0.5rem;
  `;
  return <div className={popup + ' ' + className}>{children}</div>;
};

export default PopUp;
