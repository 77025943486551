import React from 'react';
import { Layout, ResetPasswordForm } from '../components/Components';

const ResetPassword = props => {
  return (
    <Layout>
      <h1 className="headline">Passwort zurücksetzten</h1>
      <ResetPasswordForm {...props} />
    </Layout>
  );
};

export default ResetPassword;
