import React from 'react';
import {
  MemberList,
  StagedAttendeesList,
  Layout
} from '../components/Components';

const Mitglieder = props => {
  return (
    <Layout>
      <StagedAttendeesList />
      <h1 className="headline">Mitglieder Liste</h1>
      <MemberList />
    </Layout>
  );
};

export default Mitglieder;
