import React, { useState } from 'react';
import { FontAwesomeButton } from './Components';
import { css } from 'emotion';

const InputField = ({ type, id, placeholder, value, ...props }) => {
  const typeArray = ['text', 'password', 'email', 'number', 'search', 'url'];
  const [inputValue, setInputValue] = useState(value);
  const mq = [600, 960, 1280, 1920].map(bp => `@media (max-width: ${bp}px)`);
  const input = css`
    font-size: 1rem;
    line-height: 1.5;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    background-clip: padding-box;
    outline: none;
    padding: 0.75rem;
    width: calc(100% - 1.5rem);
    &::placeholder {
      opacity: 0;
    }
    &:not(:placeholder-shown) {
      padding-top: 1.25rem;
      padding-bottom: 0.25rem;
    }
    &:not(:placeholder-shown) ~ label {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      font-size: 0.75rem;
    }
  `;
  const label = css`
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    margin-bottom: 0;
    line-height: 1.5;
    color: gray;
    cursor: text;
    border: 1px solid transparent;
    border-radius: 0.25em;
    transition: all 0.1s ease-in-out;
    pointer-events: none;
    padding: 0.75rem;
  `;
  if (typeArray.includes(type)) {
    return (
      <div
        className={css`
          font-size: 1rem;
          position: relative;
          margin-bottom: 1rem;
          ${mq[3]} {
            width: 25rem;
          }
          ${mq[2]} {
            width: 25rem;
          }
          ${mq[1]} {
            width: 20rem;
          }
          ${mq[0]} {
            width: 15rem;
          }
        `}
      >
        <input
          type={type}
          onKeyDown={props.onKeyDown}
          disabled={props.disabled}
          className={input}
          id={id}
          name={id}
          placeholder={placeholder}
          value={inputValue}
          onChange={e => {
            setInputValue(e.target.value);
            if (props.onChange) {
              props.onChange(e);
            }
          }}
          onClick={props.onClick}
        />
        <label className={label}> {placeholder}: </label>
        {props.disabled && (
          <div
            className={css`
              position: absolute;
              width: 1.5rem !important;
              top: 1rem;
              right: 0;
            `}
          >
            <FontAwesomeButton
              onClick={props.onClick}
              icon="trash"
              colorPre="delete"
            />
          </div>
        )}
      </div>
    );
  }
};

export default InputField;
