import React, { useState } from 'react';
import { InputField, ButtonWrapper, Button, Form } from './Components';
import fetchAPI from '../functions/fetchAPI';
import { css } from 'emotion';
import { Link } from 'react-router-dom';

const ResetPasswordForm = props => {
  const [submitted, setSubmitted] = useState(false);
  const [afterSubmitText, setAfterSubmitText] = useState();
  const [afterSubmitColor, setAfterSubmitColor] = useState();
  async function handleSubmit() {
    await fetchAPI('post', {
      path: '/authAPI/reset_password',
      body: {
        token: props.location.search.slice(7),
        new_password: document.getElementById('new_password').value,
        new_password_confirm: document.getElementById('new_password_confirm')
          .value
      }
    }).then(res => {
      if (res.status === 200) {
        setAfterSubmitText('Passwort erfolgreich zurückgesetzt.');
        setAfterSubmitColor('#3ba93b');
        setSubmitted(true);
      }
      if (res.status === 422) {
        alert(
          'Irgendwas stimmt mit deiner Eingabe nicht...überprüfe und versuch es erneut!'
        );
      }
      if (res.status === 403) {
        setAfterSubmitText(
          'Der Token scheint ungültig oder abgelaufen zu sein. Lasse dir einen neuen zuschicken.'
        );
        setAfterSubmitColor('#c43b3b');
        setSubmitted(true);
      }
    });
  }
  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  }
  if (submitted) {
    return (
      <div
        className={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        `}
      >
        <p
          className={css`
            color: ${afterSubmitColor};
          `}
        >
          {afterSubmitText}
        </p>
        <Link to="/login">zum Login</Link>
      </div>
    );
  } else {
    return (
      <Form>
        <InputField
          id="new_password"
          onKeyDown={e => {
            handleKeyDown(e);
          }}
          type="password"
          value=""
          placeholder="Neues Passwort"
        />
        <InputField
          id="new_password_confirm"
          onKeyDown={e => {
            handleKeyDown(e);
          }}
          type="password"
          value=""
          placeholder="Neues Passwort bestätigen"
        />
        <ButtonWrapper>
          <Button
            type="button"
            onClick={() => {
              handleSubmit();
            }}
          >
            Zurücksetzten
          </Button>
        </ButtonWrapper>
      </Form>
    );
  }
};

export default ResetPasswordForm;
