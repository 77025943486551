import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  InputField,
  DropdownWithInput,
  Dropdown,
  Form,
  ButtonWrapper
} from './Components';
import fetchAPI from '../functions/fetchAPI';
import { validate } from '../functions/validate';
import { css } from 'emotion';

const GroupForm = props => {
  const [trainerSearchValue, setTrainerSearchValue] = useState(undefined);
  const [trainerSearchList, setTrainerSearchList] = useState(undefined);
  const [helperSearchValue, setHelperSearchValue] = useState(undefined);
  const [helperSearchList, setHelperSearchList] = useState(undefined);
  const [gymSearchValue, setGymSearchValue] = useState(undefined);
  const [gymSearchList, setGymSearchList] = useState(undefined);
  const [firstTimeTrainer, setFirstTimeTrainer] = useState(true);
  const [firstTimeHelper, setFirstTimeHelper] = useState(true);
  const [firstTimeGym, setFirstTimeGym] = useState(true);
  const [trainerList, setTrainerList] = useState([]);
  const [helperList, setHelperList] = useState([]);
  const [gymList, setGymList] = useState([]);
  const [groupTrainer, setGroupTrainer] = useState();
  const [groupHelper, setGroupHelper] = useState();
  const [selectedWeekday, setSelectedWeekday] = useState('');
  const weekdays = [
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
    'Sonntag'
  ];
  const [dropdownValueHelper, setDropdownValueHelper] = useState({
    full_name: ''
  });
  const [dropdownValueTrainer, setDropdownValueTrainer] = useState({
    full_name: ''
  });
  const [dropdownValueGym, setDropdownValueGym] = useState({
    gym: ''
  });
  const [group, setGroup] = useState(undefined);
  const history = useHistory();
  const [reload, setReload] = useState(false);
  const [showSave, setShowSave] = useState(false);
  const [saveText, setSaveText] = useState('');
  const [saveColor, setSaveColor] = useState('');

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    async function fetchGroup() {
      if (props.groupID) {
        await fetchAPI('post', {
          path: '/groupAPI/getgroupinfo',
          body: { groupID: props.groupID },
          signal: signal
        })
          .then(res => res.json())
          .then(res => {
            setGroup(res.group);
            setGroupTrainer(res.trainer);
            setGroupHelper(res.helper);
            setSelectedWeekday(res.group.weekday);
          })
          .catch(err => {});
      }
    }

    async function fetchTrainerList() {
      await fetchAPI('post', {
        path: '/userAPI/getpositionlist',
        body: { position: 'ÜL' },
        signal: signal
      })
        .then(res => res.json())
        .then(res => setTrainerList(res))
        .catch(err => {});
    }

    async function fetchHelperList() {
      await fetchAPI('post', {
        path: '/userAPI/getpositionlist',
        body: { position: 'HL' },
        signal: signal
      })
        .then(res => res.json())
        .then(res => setHelperList(res))
        .catch(err => {});
    }

    async function fetchGymList() {
      await fetchAPI('post', {
        path: '/groupAPI/getgym',
        signal: signal
      })
        .then(res => res.json())
        .then(res => setGymList(res))
        .catch(err => {});
    }

    fetchGroup();
    fetchTrainerList();
    fetchHelperList();
    fetchGymList();
    return () => {
      abortController.abort();
    };
  }, [props.groupID, reload]);

  async function handleSubmit() {
    const reqBody = {
      groupID: props.method === 'put' ? props.groupID : null,
      name: document.getElementById('name').value,
      weekday: selectedWeekday,
      start_time: document.getElementById('start_time').value,
      end_time: document.getElementById('end_time').value,
      gym: dropdownValueGym.gym,
      trainerID: dropdownValueTrainer.userID,
      helperID: dropdownValueHelper.userID
        ? dropdownValueHelper.userID
        : undefined
    };
    if (props.method === 'post') {
      await fetchAPI('post', {
        path: '/groupAPI/addgroup',
        body: reqBody
      }).then(res => {
        if (res.status === 200) {
          handleSave();
        }
        if (res.status === 422) {
          handleSave(
            'Irgendwas ist schief gelaufen überprüfen Sie Ihre Eingabe!',
            '#c43b3b'
          );
        }
      });
    } else if (props.method === 'put') {
      await fetchAPI('put', {
        path: '/groupAPI/editgroup',
        body: reqBody
      }).then(res => {
        if (res.status === 200) {
          handleSave('Gespeichert!', '#3ba93b');
        }
        if (res.status === 422) {
          handleSave(
            'Irgendwas ist schief gelaufen überprüfen Sie Ihre Eingabe!',
            '#c43b3b'
          );
        }
      });
    }
  }

  function handleSave(text, color) {
    setSaveText(text);
    setSaveColor(color);
    setShowSave(true);
    setTimeout(() => {
      setShowSave(false);
    }, 2000);
  }

  function getTrainer() {
    setFirstTimeTrainer(false);
    setDropdownValueTrainer({
      userID: groupTrainer.userID,
      first_name: groupTrainer.first_name,
      last_name: groupTrainer.last_name,
      full_name: groupTrainer.full_name
    });
  }

  function getHelper() {
    setFirstTimeHelper(false);
    setDropdownValueHelper({ full_name: '' });
  }

  function getGym() {
    setFirstTimeGym(false);
    setDropdownValueGym({ gym: group.gym });
  }

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  }

  function listToLi(list, type) {
    return list.map(e => {
      return (
        <li
          className="dd-list-item"
          key={e.userID}
          onMouseDown={() => {
            if (type === 'trainer') {
              setDropdownValueTrainer({
                userID: e.userID,
                full_name: e.full_name
              });
              setTrainerSearchValue(e.full_name);
            } else if (type === 'helper') {
              setDropdownValueHelper({
                userID: e.userID,
                full_name: e.full_name
              });
              setHelperSearchValue(e.full_name);
            }
          }}
        >
          {e.full_name}
        </li>
      );
    });
  }

  function gymToLi(list) {
    return list.map(e => {
      return (
        <li
          className="dd-list-item"
          key={e.gym}
          onMouseDown={() => {
            setDropdownValueGym({ gym: e.gym });
            setGymSearchValue(e.gym);
          }}
        >
          {e.gym}
        </li>
      );
    });
  }

  function isArrayHelper() {
    if (Array.isArray(groupHelper)) {
      return groupHelper.map(helper => {
        return (
          <InputField
            key={helper.userID}
            onClick={() => {
              removeHelperFromGroup(helper);
            }}
            disabled="disabled"
            className="helper-input-dis"
            type="text"
            value={helper.full_name}
            placeholder="Helfer (Vorname, Nachname)"
          />
        );
      });
    }
  }

  async function removeHelperFromGroup(helper) {
    if (
      window.confirm(
        `Wollen sie den Helfer: '${helper.full_name}' wirklich aus der Gruppe '${group.name}' entfernen?`
      )
    ) {
      await fetchAPI('delete', {
        path: '/userAPI/removehelperfromgroup',
        body: { userID: helper.userID, groupID: props.groupID }
      }).then(res => {
        if (res.status === 200) {
          alert('Helfer erfolgreich entfernt.');
        }
      });
      setReload(!reload);
    }
  }

  function trainerSearch(e) {
    setTrainerSearchValue(e.target.value);
    setTrainerSearchList(filterSearch(trainerList, e.target.value));
  }

  function helperSearch(e) {
    setHelperSearchValue(e.target.value);
    setHelperSearchList(filterSearch(helperList, e.target.value));
  }

  function gymSearch(e) {
    setGymSearchValue(e.target.value);
    setGymSearchList(gymFilterSearch(gymList, e.target.value));
  }

  function gymFilterSearch(arr, query) {
    return arr.filter(
      e => e.gym.toLowerCase().indexOf(query.toLowerCase()) > -1
    );
  }

  function filterSearch(arr, query) {
    return arr.filter(
      e => e.full_name.toLowerCase().indexOf(query.toLowerCase()) > -1
    );
  }

  function handleValidate(type, e) {
    if (!validate(type, e.target.value)) {
      e.target.setAttribute(
        'style',
        'box-shadow: 0px 0px 5px 3px rgba(196,59,59,1);'
      );
    } else {
      e.target.setAttribute('style', 'box-shadow: 0px 0px 0px 0px #fff;');
    }
  }

  return (
    <Form>
      {
        <p
          className={css`
            opacity: ${showSave ? 1 : 0};
            color: ${saveColor};
            position: absolute;
            z-index: 10;
            top: 9.5rem;
            transition-timing-function: ease-out;
            transition-duration: 0.33s;
          `}
        >
          {saveText}
        </p>
      }
      {(props.method === 'post' || group) && (
        <InputField
          id="name"
          type="text"
          onKeyDown={handleKeyDown}
          value={group ? group.name : ''}
          placeholder="Name"
          onChange={e => {
            handleValidate('NAME', e);
          }}
        />
      )}
      {(props.method === 'post' || group) && (
        <div className="weekday-dd gf-dd">
          <Dropdown
            placeholder="Wochentag:"
            title={
              selectedWeekday.length <= 0
                ? 'Wähle einen Wochentag'
                : selectedWeekday
            }
            list={weekdays}
            onListItemClick={setSelectedWeekday}
          />
        </div>
      )}
      {(props.method === 'post' || group) && (
        <InputField
          id="start_time"
          type="text"
          onKeyDown={handleKeyDown}
          value={group ? group.start_time : ''}
          placeholder="Anfang (HH:MM)"
          onChange={e => {
            handleValidate('TIME', e);
          }}
        />
      )}
      {(props.method === 'post' || group) && (
        <InputField
          id="end_time"
          type="text"
          onKeyDown={handleKeyDown}
          value={group ? group.end_time : ''}
          placeholder="Ende (HH:MM)"
          onChange={e => {
            handleValidate('TIME', e);
          }}
        />
      )}
      <div className="gym-dd gf-dd">
        {(props.method === 'post' || group) && (
          <DropdownWithInput
            list={gymToLi(gymSearchList ? gymSearchList : gymList)}
            id="gym"
            value={
              firstTimeGym && group
                ? getGym()
                : gymSearchValue !== undefined
                ? gymSearchValue
                : dropdownValueGym.gym
            }
            placeholder="Turnhalle"
            onChange={e => {
              gymSearch(e);
            }}
            zIndex={6}
          />
        )}
      </div>
      <div className="trainer-dd gf-dd">
        {(props.method === 'post' || (group && groupTrainer)) && (
          <DropdownWithInput
            list={listToLi(
              trainerSearchList ? trainerSearchList : trainerList,
              'trainer'
            )}
            id="trainer"
            value={
              firstTimeTrainer && group && groupTrainer
                ? getTrainer()
                : trainerSearchValue !== undefined
                ? trainerSearchValue
                : dropdownValueTrainer.full_name
            }
            placeholder="Übungsleiter (Vorname, Nachname)"
            onChange={e => {
              trainerSearch(e);
            }}
            zIndex={4}
          />
        )}
      </div>
      {group && groupHelper && isArrayHelper()}
      <div className="helper-dd gf-dd">
        {(props.method === 'post' || group) && (
          <DropdownWithInput
            list={listToLi(
              helperSearchList ? helperSearchList : helperList,
              'helper'
            )}
            id="helper"
            value={
              firstTimeHelper && group
                ? getHelper()
                : helperSearchValue !== undefined
                ? helperSearchValue
                : dropdownValueHelper.full_name
            }
            placeholder="Helfer (Vorname, Nachname)"
            onChange={e => {
              helperSearch(e);
            }}
          />
        )}
      </div>
      <ButtonWrapper>
        <Button
          id="back"
          type="button"
          onClick={() => {
            history.goBack();
          }}
        >
          zurück
        </Button>
        <Button id="groupButton" type="button" onClick={handleSubmit}>
          {group ? 'Speichern' : 'Hinzufügen'}
        </Button>
      </ButtonWrapper>
    </Form>
  );
};

export default GroupForm;
