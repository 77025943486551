import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, InputField, Form, ButtonWrapper } from './Components';
import fetchAPI from '../functions/fetchAPI';
import { validate } from '../functions/validate';
import { css } from 'emotion';

const MemberForm = props => {
  const [member, setMember] = useState(undefined);
  const history = useHistory();
  const [reload, setReload] = useState(false);
  const [showSave, setShowSave] = useState(false);
  const [saveText, setSaveText] = useState('');
  const [saveColor, setSaveColor] = useState('');
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    async function fetchMember() {
      if (props.memberID) {
        await fetchAPI('post', {
          path: '/memberAPI/getmemberbyid',
          body: { memberID: props.memberID },
          signal: signal
        })
          .then(res => res.json())
          .then(res => setMember(res));
      }
    }
    fetchMember();
    return () => {
      abortController.abort();
    };
  }, [reload, props.memberID]);

  async function handleClick() {
    let phone_numbers = [];
    let temp = [...document.getElementsByClassName('phoneNumber')];
    temp.forEach(number => {
      if (number.value.length > 0) {
        phone_numbers.push(number.value);
      }
    });

    const reqBody = {
      memberID: props.method === 'put' ? props.memberID : null,
      first_name: document.getElementById('first_name').value,
      last_name: document.getElementById('last_name').value,
      birthday: document.getElementById('birthday').value,
      phone_number: document.getElementById('phone_number').value
    };

    if (props.method === 'post') {
      await fetchAPI('post', {
        path: '/memberAPI/addmember',
        body: reqBody
      }).then(res => {
        if (res.status === 200) {
          handleSave('Gespeichert!', '#3ba93b');
        }
        if (res.status === 422) {
          handleSave(
            'Irgendwas ist schief gelaufen überprüfen Sie Ihre Eingabe!',
            '#c43b3b'
          );
        }
      });
    } else if (props.method === 'put') {
      await fetchAPI('put', {
        path: '/memberAPI/editmember',
        body: reqBody
      }).then(res => {
        if (res.status === 200) {
          handleSave('Gespeichert!', '#3ba93b');
        }
        if (res.status === 422) {
          handleSave(
            'Irgendwas ist schief gelaufen überprüfen Sie Ihre Eingabe!',
            '#c43b3b'
          );
        }
      });
    }
  }

  function handleSave(text, color) {
    setSaveText(text);
    setSaveColor(color);
    setShowSave(true);
    setTimeout(() => {
      setShowSave(false);
    }, 2500);
  }

  async function deletePhone(number) {
    const delBody = {
      memberID: props.method === 'put' ? props.memberID : null,
      phone_number: number
    };
    if (
      window.confirm(`Wollen Sie die Telefonnummer ${number} wirklich löschen?`)
    ) {
      await fetchAPI('delete', {
        path: '/memberAPI/deletephone',
        body: delBody
      });
      setReload(!reload);
    }
  }

  function phoneNumbers() {
    if (Array.isArray(member.phone_number)) {
      return member.phone_number.map(number => {
        return (
          <InputField
            key={number}
            onClick={() => {
              deletePhone(number);
            }}
            disabled="disabled"
            type="text"
            value={number}
            placeholder="Telefonnummer"
          />
        );
      });
    } else if (member.phone_number) {
      return (
        <InputField
          onClick={() => {
            deletePhone(member.phone_number);
          }}
          disabled="disabled"
          type="text"
          value={member.phone_number}
          placeholder="Telefonnummer"
        />
      );
    }
  }

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      handleClick();
    }
  }

  function handleValidate(type, e) {
    if (!validate(type, e.target.value)) {
      e.target.setAttribute(
        'style',
        'box-shadow: 0px 0px 5px 3px rgba(196,59,59,1);'
      );
    } else {
      e.target.setAttribute('style', 'box-shadow: 0px 0px 0px 0px #fff;');
    }
  }

  return (
    <Form>
      {
        <p
          className={css`
            opacity: ${showSave ? 1 : 0};
            color: ${saveColor};
            position: absolute;
            z-index: 100;
            top: 9.5rem;
            transition-timing-function: ease-out;
            transition-duration: 0.33s;
          `}
        >
          {saveText}
        </p>
      }
      {(props.method === 'post' || member) && (
        <InputField
          id="first_name"
          type="text"
          onKeyDown={handleKeyDown}
          value={member ? member.first_name : ''}
          placeholder="Vorname"
          onChange={e => {
            handleValidate('NAME', e);
          }}
        />
      )}
      {(props.method === 'post' || member) && (
        <InputField
          id="last_name"
          type="text"
          onKeyDown={handleKeyDown}
          value={member ? member.last_name : ''}
          placeholder="Nachname"
          onChange={e => {
            handleValidate('NAME', e);
          }}
        />
      )}
      {(props.method === 'post' || member) && (
        <InputField
          id="birthday"
          type="text"
          onKeyDown={handleKeyDown}
          value={member ? member.birthday : ''}
          placeholder="Geburtstag (JJJJ-MM-TT)"
          onChange={e => {
            handleValidate('DATE', e);
          }}
        />
      )}
      {member && phoneNumbers()}
      <InputField
        id="phone_number"
        onKeyDown={handleKeyDown}
        type="text"
        value=""
        placeholder="Telefonnummer"
        onChange={e => {
          handleValidate('PHONE', e);
        }}
      />
      <ButtonWrapper>
        <Button
          id="back"
          type="button"
          onClick={() => {
            history.goBack();
          }}
        >
          zurück
        </Button>
        <Button id="memberButton" type="button" onClick={handleClick}>
          {member ? 'Speichern' : 'Hinzufügen'}
        </Button>
      </ButtonWrapper>
    </Form>
  );
};

export default MemberForm;
