import React, { useContext } from 'react';
import { css } from 'emotion';
import { NavLink, Link } from 'react-router-dom';
import { User } from '../functions/ReactContext';

const NavLinkList = ({ vertical, onClick }) => {
  const user = useContext(User);
  const navLink = css`
    color: black;
    text-decoration: none;
    margin-right: 0.75rem;
    outline: none;
    &:hover {
      color: white;
    }
  `;
  const navLinkActive = css`
    color: white !important;
  `;
  const navLinkContainer = css`
    background-color: #ee3b3b;
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
  `;
  const navLinkVertical = css`
    flex-direction: column;
    flex: 1 100%;
    align-items: flex-start;
    line-height: 2rem;
    font-size: 1.2rem;
  `;
  return (
    <ul className={navLinkContainer + (vertical ? ' ' + navLinkVertical : '')}>
      {user && user.position === 0 && (
        <li
          onClick={() => {
            onClick();
          }}
        >
          <NavLink
            className={css`
              ${navLink}
            `}
            to="/mitglieder"
            tabIndex="-1"
            activeClassName={css`
              ${navLinkActive}
            `}
          >
            Mitglieder
          </NavLink>
        </li>
      )}
      {user && (
        <li
          onClick={() => {
            onClick();
          }}
        >
          <NavLink
            className={css`
              ${navLink}
            `}
            to="/gruppen"
            tabIndex="-1"
            activeClassName={css`
              ${navLinkActive}
            `}
          >
            Gruppen
          </NavLink>
        </li>
      )}
      {user && (
        <li
          onClick={() => {
            onClick();
          }}
        >
          <NavLink
            className={css`
              ${navLink}
            `}
            to="/stundenplan"
            tabIndex="-1"
            activeClassName={css`
              ${navLinkActive}
            `}
          >
            Stundenplan
          </NavLink>
        </li>
      )}
      {user && (
        <li
          onClick={() => {
            onClick();
          }}
        >
          <NavLink
            className={css`
              ${navLink}
            `}
            to="/profil"
            tabIndex="-1"
            activeClassName={css`
              ${navLinkActive}
            `}
          >
            Profil
          </NavLink>
        </li>
      )}
      {user && (
        <li
          onClick={() => {
            onClick();
          }}
        >
          <Link
            className={css`
              ${navLink}
            `}
            to={{
              pathname: '/login',
              state: {
                logout: true,
                logoutReason: 'Du wurdest erfolgreich ausgeloggt.'
              }
            }}
            tabIndex="-1"
          >
            Logout
          </Link>
        </li>
      )}
    </ul>
  );
};

export default NavLinkList;
