import React, { useState, useEffect } from 'react';
import { FontAwesomeButton, Confirm, Table, ButtonWrapper } from './Components';
import fetchAPI from '../functions/fetchAPI';
import { css } from 'emotion';

const InactiveAttendeesList = props => {
  const [inactiveAttendees, setInactiveAttendees] = useState([]);
  const [inactiveMember, setInactiveMember] = useState(undefined);
  const [inactiveMemberHidden, setInactiveMemberHidden] = useState(true);
  const [inactiveRemove, setInactiveRemove] = useState(undefined);
  const [inactiveRemoveHidden, setInactiveRemoveHidden] = useState(true);
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    async function fetchInactiveAttendees() {
      await fetchAPI('post', {
        path: '/memberAPI/getattendee',
        body: {
          groupID: props.groupID,
          type: 'inactive'
        },
        signal: signal
      })
        .then(res => res.json())
        .then(res => setInactiveAttendees(res))
        .catch(err => {});
    }
    fetchInactiveAttendees();
    return () => {
      abortController.abort();
    };
  }, [props.groupID, props.refresh]);

  function inactiveAttendeeRows() {
    return inactiveAttendees.map(attendee => {
      let { memberID, first_name, last_name, phone_number } = attendee;
      if (Array.isArray(phone_number)) {
        phone_number = phoneHelp(phone_number);
      }
      return (
        <tr key={memberID}>
          <td>{memberID}</td>
          <td>{first_name}</td>
          <td>{last_name}</td>
          <td>{phone_number}</td>
          <td>
            <ButtonWrapper tdWrapper>
              <FontAwesomeButton
                colorPre="add"
                icon="plus"
                onClick={() => {
                  toggleInactiveToMember(memberID);
                }}
              />
              {props.groupID && (
                <FontAwesomeButton
                  colorPre="delete"
                  icon="minus"
                  onClick={() => {
                    toggleInactiveRemove(memberID);
                  }}
                />
              )}
            </ButtonWrapper>
          </td>
        </tr>
      );
    });
  }

  async function inactiveToMember() {
    await fetchAPI('post', {
      path: '/memberAPI/inactivetoattendee',
      method: 'post',
      body: {
        memberID: inactiveMember,
        groupID: props.groupID
      }
    })
      .then(toggleInactiveToMember())
      .catch(err => {});
  }

  async function removeMember() {
    await fetchAPI('post', {
      path: '/memberAPI/removememberfromgroup',
      body: {
        memberID: inactiveRemove,
        groupID: props.groupID
      }
    })
      .then(toggleInactiveRemove())
      .catch(err => {});
  }

  function toggleInactiveToMember(memberID) {
    setInactiveMemberHidden(!inactiveMemberHidden);
    if (inactiveMember === undefined) {
      setInactiveMember(memberID);
    } else {
      setInactiveMember(undefined);
    }
  }

  function toggleInactiveRemove(memberID) {
    setInactiveRemoveHidden(!inactiveRemoveHidden);
    if (inactiveRemove === undefined) {
      setInactiveRemove(memberID);
    } else {
      setInactiveRemove(undefined);
    }
  }

  function phoneHelp(phoneArray) {
    return (
      <div
        className={css`
          list-style-type: none;
          padding: 0;
        `}
      >
        {phoneArray.map(number => {
          return <li key={number.phone_number}>{number.phone_number}</li>;
        })}{' '}
      </div>
    );
  }

  return (
    <div className="InactiveAttendeesList">
      {inactiveAttendees.length > 0 && (
        <h2 className="headline">inaktive Teilnehmer</h2>
      )}
      {inactiveAttendees.length > 0 && (
        <Table
          tableHead={
            <tr>
              <th>ID</th>
              <th>Vorname</th>
              <th>Nachname</th>
              <th>Telefon</th>
              <th></th>
            </tr>
          }
          tableBody={inactiveAttendeeRows()}
        />
      )}
      {!inactiveMemberHidden && (
        <Confirm
          onClickBack={() => {
            toggleInactiveToMember();
          }}
          onClickConfirm={() => {
            inactiveToMember();
            props.onChange();
          }}
        >
          Wollen Sie den Teilnehmer mit der ID: {inactiveMember} wirklich in die
          aktive Teilnehmerliste verschieben?
        </Confirm>
      )}
      {!inactiveRemoveHidden && (
        <Confirm
          onClickBack={() => {
            toggleInactiveRemove();
          }}
          onClickConfirm={() => {
            removeMember();
            props.onChange();
          }}
        >
          Wollen Sie den Teilnehmer mit der ID: {inactiveRemove} wirklich aus
          der Gruppe entfernen?
        </Confirm>
      )}
    </div>
  );
};

export default InactiveAttendeesList;
