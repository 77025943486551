export function validate(type, obj) {
  switch (type) {
    case 'NAME':
      return /^[a-zA-Z -]*$/.test(obj);
    case 'PASSWORT':
      return /^[a-zA-Z0-9üöäÜÖÄ^!"§$%&/()=?`´+#*',.-;::_<>€@]{8,32}$/.test(obj);
    case 'EMAIL':
      // eslint-disable-next-line
      return /^(?:[a-zöäü0-9!#$%&'*+/=?^_`\{|\}~-]+(?:\.[a-zäöü0-9!#$%&'*+/=?^_`\{|\}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zäöü0-9](?:[a-zäöü0-9-]*[a-zäöü0-9])?\.)+[a-zäöü0-9](?:[a-zäöü0-9-]*[a-zäöü0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zäöü0-9-]*[a-zäöü0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/.test(
        obj
      );
    case 'ZIP':
      return /[0-9]{5}/.test(obj);
    case 'CITY':
      return /^[a-zA-Z -]*$/.test(obj);
    case 'STREET':
      return /^[A-ZÄÖÜa-zäöü ]+[0-9]+[a-z]?$/.test(obj);
    case 'DATE':
      return isValidDate(obj);
    case 'GENDER':
      return ['m', 'w', 'd'].includes(obj);
    case 'WEEKDAY':
      return [
        'Montag',
        'Dienstag',
        'Mittwoch',
        'Donnerstag',
        'Freitag',
        'Samstag',
        'Sonntag'
      ].includes(obj);
    case 'ID':
      return /^\d+$/.test(obj);
    case 'POSITION':
      return [0, 1, 2].includes(obj);
    case 'POSITION_FULL':
      return ['ÜLmS', 'ÜLoS', 'HLmS', 'HLoS', 'none'].includes(obj);
    case 'PHONE':
      if (Array.isArray(obj)) {
        return obj.every(e => /^(?:\+49 )?[\d ]+$/.test(e));
      }
      return /^(?:\+49)?[\d ]+$/.test(obj);
    case 'TIME':
      return /^(?:[0-1][0-9]):(?:00)$|^(?:2[0-3]):(?:00)$|^(?:[0-1][0-9]):(?:15)$|^(?:2[0-3]):(?:15)$|^(?:[0-1][0-9]):(?:30)$|^(?:2[0-3]):(?:30)$|^(?:[0-1][0-9]):(?:45)$|^(?:2[0-3]):(?:45)$/.test(
        obj
      );
    default:
      return false;
  }
}

function isValidDate(dateString) {
  var regEx = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateString.match(regEx)) return false;
  var d = new Date(dateString);
  var dNum = d.getTime();
  if (!dNum && dNum !== 0) return false;
  return d.toISOString().slice(0, 10) === dateString;
}
