import React, {
  useEffect,
  useState,
  useReducer,
  useCallback,
  useContext
} from 'react';
import {
  FontAwesomeButton,
  Confirm,
  Button,
  Table,
  ButtonWrapper
} from './Components';
import fetchAPI from '../functions/fetchAPI';
import {
  getNextDay,
  formatDay,
  dateToDBString
} from '../functions/dateFunctions';
import { User } from '../functions/ReactContext';
import { css } from 'emotion';
const AttendeesList = props => {
  const user = useContext(User);
  const [attendeeLoaded, setAttendeeLoaded] = useState(false);
  const [presentLoaded, setPresentLoaded] = useState(false);
  const [showSave, setShowSave] = useState(false);
  const [confirmInactiveHidden, setConfirmInactiveHidden] = useState(true);
  const [confirmAttendee, setConfirmAttendee] = useState(undefined);
  const [editMode, setEditMode] = useState(false);
  const [attendees, setAttendees] = useState([]);
  const [attendeesPresent, dispatch] = useReducer(presentReducer, []);

  const attendeeInit = useCallback(
    res => {
      if (!attendeeLoaded) {
        dispatch({ type: 'ADD_ATTENDEES', attendees: res });
      }
    },
    [attendeeLoaded]
  );
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    async function fetchAttendees() {
      await fetchAPI('post', {
        path: '/memberAPI/getattendee',
        body: { groupID: props.groupID, type: 'active' },
        signal: signal
      })
        .then(res => res.json())
        .then(res => {
          setAttendeeLoaded(true);
          setAttendees(res);
          attendeeInit(res);
        })
        .catch(err => {});
    }
    if (!attendeeLoaded) {
      fetchAttendees();
    }
    async function fetchPresent() {
      await fetchAPI('post', {
        path: '/memberAPI/getgrouppresentattendee',
        body: {
          date: dateToDBString(getNextDay(props.weekday)),
          groupID: props.groupID,
          userID: user.userID
        },
        signal: signal
      })
        .then(res => res.json())
        .then(res => {
          setPresentLoaded(true);
          res.forEach(e => {
            dispatch({
              type: 'ADD_PRESENT',
              memberID: e.attendee_memberID,
              save: false
            });
          });
        })
        .catch(() => {});
    }
    if (!presentLoaded && attendeeLoaded) {
      fetchPresent();
    }

    return () => {
      abortController.abort();
    };
  }, [
    props.groupID,
    props.refresh,
    attendeeInit,
    props.weekday,
    attendeeLoaded,
    user.userID,
    attendees,
    presentLoaded,
    attendeesPresent
  ]);

  function attendeeRows() {
    if (presentLoaded && attendeeLoaded) {
      return attendees.map(attendee => {
        let {
          memberID,
          first_name,
          last_name,
          phone_number,
          present
        } = attendee;
        if (Array.isArray(phone_number)) {
          phone_number = phoneHelp(phone_number);
        }
        return (
          <tr key={memberID}>
            <td>
              {editMode ? (
                <ButtonWrapper tdWrapper>
                  <FontAwesomeButton
                    colorPre="delete"
                    icon="user-minus"
                    onClick={() => {
                      toggleConfirmInactive();
                      setConfirmAttendee(attendee);
                    }}
                  />
                </ButtonWrapper>
              ) : (
                memberID
              )}
            </td>
            <td>{first_name}</td>
            <td>{last_name}</td>
            <td>{phone_number}</td>
            {!editMode && (
              <td
                className={css`
                  background-color: ${present ? 'lightgreen' : 'lightcoral'};
                `}
                onMouseDown={e => {
                  dispatch({
                    memberID: memberID,
                    type: present ? 'REMOVE_PRESENT' : 'ADD_PRESENT',
                    save: true
                  });
                }}
              ></td>
            )}
          </tr>
        );
      });
    }
  }

  function presentReducer(state, action) {
    switch (action.type) {
      case 'ADD_ATTENDEES':
        return [
          ...action.attendees.map(attendee => {
            return {
              memberID: attendee.memberID,
              present: attendee.present ? attendee.present : false
            };
          })
        ];
      case 'ADD_PRESENT':
        if (action.save) {
          setShowSave(true);
        }
        attendees.forEach(e => {
          if (e.memberID === action.memberID) {
            e.present = true;
          }
        });
        return [
          ...state.map(attendee => {
            if (attendee.memberID === action.memberID) {
              return { ...attendee, present: true };
            }
            return attendee;
          })
        ];

      case 'REMOVE_PRESENT':
        if (action.save) {
          setShowSave(true);
        }
        attendees.forEach(e => {
          if (e.memberID === action.memberID) {
            e.present = false;
          }
        });
        return [
          ...state.map(attendee => {
            if (attendee.memberID === action.memberID) {
              return { ...attendee, present: false };
            }
            return attendee;
          })
        ];
      default:
        return state;
    }
  }

  async function confirmInactive() {
    await fetchAPI('post', {
      path: '/memberAPI/attendeetoinactive',
      body: {
        groupID: props.groupID,
        memberID: confirmAttendee.memberID
      }
    })
      .then(setConfirmAttendee(undefined))
      .catch(err => {});
  }

  function toggleConfirmInactive() {
    setConfirmInactiveHidden(!confirmInactiveHidden);
  }

  function phoneHelp(phoneArray) {
    return (
      <div
        className={css`
          list-style-type: none;
          padding: 0;
        `}
      >
        {phoneArray.map(number => {
          return <li key={number.phone_number}>{number.phone_number}</li>;
        })}{' '}
      </div>
    );
  }

  async function setGroupPresentMember() {
    await fetchAPI('post', {
      path: '/memberAPI/setgrouppresentattendee',
      body: {
        date: dateToDBString(getNextDay(props.weekday)),
        groupID: props.groupID,
        userID: user.userID,
        attendees: attendeesPresent
      }
    }).then(res => {
      if (res.status === 200) {
        alert('Erfolgreich gespeichert.');
      } else if (res.status === 422) {
        alert(
          'Das Eintragen der Anwesenheit ist nur am gleichen Tag möglich.\n' +
            'Falls der Tag richtig ist, prüfe deine anderen Eingaben und wende dich ansonsten an einen Administrator.'
        );
        setPresentLoaded(false);
      } else {
        setPresentLoaded(false);
      }
      setShowSave(false);
    });
  }

  return (
    <div className="AttendeesList">
      <h2 className="headline">Teilnehmer</h2>
      <ButtonWrapper>
        <Button
          type="button"
          onClick={() => {
            setEditMode(!editMode);
          }}
        >
          Bearbeiten
        </Button>
        <Button type="button" onClick={props.onClickAdd}>
          Hinzufügen
        </Button>
      </ButtonWrapper>
      <Table
        tableHead={
          <tr>
            <th>ID</th>
            <th>Vorname</th>
            <th>Nachname</th>
            <th>Telefon</th>
            {!editMode && <th>{formatDay(getNextDay(props.weekday))}</th>}
          </tr>
        }
        tableBody={attendeeRows()}
      />
      {showSave && (
        <ButtonWrapper>
          <Button
            type="button"
            onClick={() => {
              setGroupPresentMember();
            }}
          >
            Speichern
          </Button>
        </ButtonWrapper>
      )}
      {confirmAttendee && (
        <Confirm
          hidden={confirmInactiveHidden}
          onClickBack={() => {
            toggleConfirmInactive();
          }}
          onClickConfirm={() => {
            toggleConfirmInactive();
            confirmInactive();
            props.onChange();
          }}
        >
          Möchten Sie wirklich den Teilnehmer: '
          {confirmAttendee.first_name + ', ' + confirmAttendee.last_name}' in
          'inaktiv' verschieben?
        </Confirm>
      )}
    </div>
  );
};

export default AttendeesList;
