import React from 'react';
import { MemberForm, Layout } from '../components/Components';

const AddMember = props => {
  return (
    <Layout>
      <h1 className="headline">Mitglied hinzufügen</h1>
      <MemberForm method="post" />
    </Layout>
  );
};

export default AddMember;
